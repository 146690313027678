import React, { ReactDOM, forwardRef, useEffect } from "react";
import { Layout, AppBar, UserMenu, MenuItemLink } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../App.css"; //plik css

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: -10,
  },

  logo: {
    maxWidth: "60px",
  },

  bell: {
    color: "#ff0000 !important"
  }
});


const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <MenuItemLink to="/mydata" primaryText="Moje dane" />
    <MenuItemLink
      to="/user-notifications"
      primaryText="Powiadomenia"
      leftIcon={
        localStorage.getItem("hideBell") != "true" ? (
          <div style={{ padding: "0 0 10px 20px" }}>
            <FontAwesomeIcon icon={faBell} color="#FF0000" size="xs" />
          </div>
        ) : null
      }
      style={
        localStorage.getItem("hideBell") != "true"
          ? { margin: "0 0 0 -20px" }
          : null
      }
    />
  </UserMenu>
);

const CustomAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar
      {...props}
      className={"top-admin-bar"}
      style={{ backgroundColor: "#fff" }}
      userMenu={<CustomUserMenu />}
    >
      <Typography variant="h6">Witaj w panelu klienta</Typography>

      <img src="logo.svg" alt="logo" className={classes.logo} />
      <div className={"spacer"}></div>
      <Typography variant="h6" color="inherit" id="react-admin-title" />
      <IconButton
        href={"#/user-notifications"}
        style={
          localStorage.getItem("hideBell") == "true"
            ? { display: "none" } : null
        }
      >
        <FontAwesomeIcon icon={faBell} className={classes.bell} color="#FF0000" size="xs" />
      </IconButton>
    </AppBar>
  );
};

const MainLayout = (props) => {
  return <Layout {...props} appBar={CustomAppBar} />;
};

export default MainLayout;
