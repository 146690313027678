import React from "react";
import {Redirect, Route} from "react-router-dom";
import {Resource} from "react-admin";
import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";

// config
//const { permissions } = usePermissions();
import authProvider from "./config/authProvider";
import customRoutes from "./config/customRoutes";
import PermissionHandler from "./config/PermissionHandler";

// pages
import Login from "./layout/Login/Login";
import Menu from "./layout/Menu/Menu";
import NotFound from "./layout/NotFound";
import Dashboard from "./layout/Dashboard/Dashboard";
// pages - end
import MainLayout from "./layout/MainLayout";
import "./css/style.css";

// custom views
import WorkersList from "./layout/Workers/WorkersList";
import WorkersEdit from "./layout/Workers/WorkersEdit";
import WorkersCreate from "./layout/Workers/WorkersCreate";

import PickupPointsList from "./layout/PickupPoints/PickupPointsList";
import PickupPointsShow from "./layout/PickupPoints/PickupPointsShow";
import PickupPointsEdit from "./layout/PickupPoints/PickupPointsEdit";

import PayersList from "./layout/Payers/PayersList";
import PayersCreate from "./layout/Payers/PayersCreate";
import PayersEdit from "./layout/Payers/PayersEdit";
import PayersShow from "./layout/Payers/PayersShow";
import OrdersList from "./layout/Orders/OrdersList";
import OrdersShow from "./layout/Orders/OrdersShow";

import PayerDiscountsList from "./layout/PayerDiscounts/PayerDiscountsList";
import PayerDiscountsEdit from "./layout/PayerDiscounts/PayerDiscountsEdit";

import PayerUsersList from "./layout/PayerUsers/PayerUsersList";
import PayerUsersCreate from "./layout/PayerUsers/PayerUsersCreate";
import PayerUsersEdit from "./layout/PayerUsers/PayerUsersEdit";

import PkUserList from "./layout/PkUser/PkUserList";
import PkUserCreate from "./layout/PkUser/PkUserCreate";
import PkUserEdit from "./layout/PkUser/PkUserEdit";

import InvoicesList from "./layout/Invoices/InvoicesList";
import SamplesList from "./layout/Samples/SamplesList";
import SamplesCreate from "./layout/Samples/SamplesCreate";
import SamplesEdit from "./layout/Samples/SamplesEdit";

import TutorialsList from "./layout/Tutorials/TutorialsList";
import TutorialsCreate from "./layout/Tutorials/TutorialsCreate";
import TutorialsEdit from "./layout/Tutorials/TutorialsEdit";
import TutorialsShow from "./layout/Tutorials/TutorialsShow";
//import { VoivodeshipsList, VoivodeshipsEdit } from "./layout/oldVoivodeships";
// custom views - end
// i18n
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import polishMessages from "ra-language-polish";
//import * as extendMessages from "./i18n/ts";

const messages = {
  pl: {
    ...polishMessages,
    //...extendMessages.pl
  },
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pl");
// i18n - end

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});
const fetchHydra = (url, options = {}) =>
  localStorage.getItem("token")
    ? baseFetchHydra(url, {
        ...options,
        headers: new Headers(fetchHeaders()),
      })
    : baseFetchHydra(url, options);

const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(
    entrypoint,
    localStorage.getItem("token")
      ? {
          headers: new Headers(fetchHeaders()),
        }
      : {}
  ).then(
    ({ api }) => ({ api }),
    (result) => {
      // Only useful when the API endpoint is secured
      if (result.status === 401) {
        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");
        return Promise.resolve({
          api: result.api,
          customRoutes: [
            <Route
              path="/"
              render={() =>
                localStorage.getItem("token") ? (
                  window.location.reload()
                ) : (
                  <Redirect to="/login" />
                )
              }
            />,
          ],
        });
      }
      return Promise.reject(result);
    }
  );
const dataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser
);

export default () => (
  <HydraAdmin
    title="Panel Klienta TZMOONLINE"
    locale="pl"
    entrypoint={entrypoint}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    customRoutes={customRoutes}
    // pages
    menu={Menu}
    loginPage={Login}
    dashboard={Dashboard}
    catchAll={NotFound}
    layout={MainLayout}
  >
    {(permissions) => [
      <Resource
        name="voivodeships"
        list={null}
      />,


      PermissionHandler(permissions, "Invoices", "Read") && (
        <Resource name="invoices" list={InvoicesList} />
      ),

      PermissionHandler(permissions, "Orders", "Read") && (
        <Resource name="orders" list={OrdersList} show={OrdersShow} />
      ),

      <Resource name="product_orders" />,
      <Resource name="products" />,
      <Resource name="signin" />,

      PermissionHandler(permissions, "PkUsers", "Read") && (
        <Resource
          name="pk_users"
          list={PkUserList}
          create={
            PermissionHandler(permissions, "PkUsers", "Create") && PkUserCreate
          }
          edit={
            PermissionHandler(permissions, "PkUsers", "Update") && PkUserEdit
          }
        />
      ),

      PermissionHandler(permissions, "Payers", "Read") && (
        <Resource
          name="payers"
          list={PayersList}
          edit={
            PermissionHandler(permissions, "Payers", "Update") && PayersEdit
          }
          create={
            PermissionHandler(permissions, "Payers", "Create") && PayersCreate
          }
          show={
            PermissionHandler(permissions, "Payers", "Update") && PayersShow
          }
        />
      ),

      PermissionHandler(permissions, "PayerDiscounts", "Read") && (
        <Resource
          name="payer_discounts"
          list={PayerDiscountsList}
          edit={
            PermissionHandler(permissions, "PayerDiscounts", "Update")
              ? PayerDiscountsEdit
              : null
          }
        />
      ),

      PermissionHandler(permissions, "PayerUsers", "Read") && (
        <Resource
          name="payer_users"
          list={PayerUsersList}
          //create={PayerUsersCreate}
          create={
            PermissionHandler(permissions, "PayerUsers", "Create")
              ? PayerUsersCreate
              : null
          }
          edit={
            PermissionHandler(permissions, "PayerUsers", "Update")
              ? PayerUsersEdit
              : null
          }
        />
      ),

      PermissionHandler(permissions, "PayerUsers", "Read") && (
        <Resource
          name="payer_users"
          list={PayerUsersList}
          //create={PayerUsersCreate}
          create={
            PermissionHandler(permissions, "PayerUsers", "Create")
              ? PayerUsersCreate
              : null
          }
          edit={
            PermissionHandler(permissions, "PayerUsers", "Update")
              ? PayerUsersEdit
              : null
          }
        />
      ),

      PermissionHandler(permissions, "PickupPoints", "Read") && (
        <Resource
          name="pickup_points"
          list={PickupPointsList}
          show={PickupPointsShow}
          edit={
            PermissionHandler(permissions, "PickupPoints", "Update")
              ? PickupPointsEdit
              : null
          }
          // create={
          //   PermissionHandler(permissions, "PickupPoints", "Create")
          //     ? PickupPointsCreate
          //     : null
          // }
        />
      ),

      PermissionHandler(permissions, "Workers", "Read") && (
        <Resource
          name="workers"
          list={WorkersList}
          edit={
            PermissionHandler(permissions, "Workers", "Update") && WorkersEdit
          }
          create={
            PermissionHandler(permissions, "Workers", "Create") && WorkersCreate
          }
        />
      ),

      PermissionHandler(permissions, "Samples", "Read") && (
        <Resource
          name="samples"
          list={SamplesList}
          create={
            PermissionHandler(permissions, "Samples", "Create") && SamplesCreate
          }
          edit={
            PermissionHandler(permissions, "Samples", "Update") && SamplesEdit
          }
        />
      ),

      PermissionHandler(permissions, "Tutorials", "Read") && (
        <Resource
          name="tutorials"
          list={TutorialsList}
          create={
            PermissionHandler(permissions, "Tutorials", "Create") &&
            TutorialsCreate
          }
          edit={
            PermissionHandler(permissions, "Tutorials", "Update") &&
            TutorialsEdit
          }
          show={TutorialsShow}
        />
      ),
      <Resource name="tutorial_payers" />,
    ]}

    {/*{permissions => [*/}
    {/*  // Restrict access to the edit and remove views to admin only*/}
    {/*  <ResourceGuesser*/}
    {/*    name="voivodeships"*/}
    {/*    edit={permissions.includes('AlaMaKota') ? VoivodeshipsEdit : null}*/}
    {/*  />,*/}
    {/*  // Only include the categories resource for admin users*/}
    {/*  // permissions === 'admin'*/}
    {/*  //   ? <Resource name="categories" list={CategoryList} edit={CategoryEdit} icon={CategoryIcon} />*/}
    {/*  //   : null,*/}
    {/*]}*/}
  </HydraAdmin>
);
