import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, useDataProvider, usePermissions } from "react-admin";
import { withRouter } from "react-router-dom";

import { useSnackbar } from "notistack";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faCommentDollar,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faHistory,
  faHome,
  faIdCardAlt,
  faMapMarkedAlt,
  faUsers,
  faUserGraduate,
  faFileContract,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

import PermissionHandler from "../../config/PermissionHandler";

const Menu = ({ onMenuClick, logout }) => {
  const dataProvider = useDataProvider();
  const [dataAlerts, setDataAlerts] = useState();
  const [isFetched, setIsFetched] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const { loaded, permissions } = usePermissions();

  const [podImage, setPodImage] = useState("/podS.png");

  useEffect(() => {
    if (localStorage.getItem("notifyOther") == "true")
      dataProvider.getOne("", { id: "/pk_users/messages" }).then(({ data }) => {
        setDataAlerts(data);
      });
  }, [isFetched]);
  if (dataAlerts != undefined && isFetched == false) setIsFetched(true);

  return loaded ? (
    <div className="left-menu">
      <MenuItemLink
        to="/#"
        primaryText="Strona główna"
        leftIcon={<FontAwesomeIcon icon={faHome} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <hr />
      {PermissionHandler(permissions, "Invoices", "Read") && (
        <MenuItemLink
          to="/invoices"
          primaryText="Płatności"
          leftIcon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {PermissionHandler(permissions, "Orders", "Read") && (
        <MenuItemLink
          to="/orders"
          primaryText="Historia zamówień"
          leftIcon={<FontAwesomeIcon icon={faHistory} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {PermissionHandler(permissions, "Payers", "Read") && (
        <MenuItemLink
          to="/payers"
          primaryText="Płatnicy"
          leftIcon={<FontAwesomeIcon icon={faCommentDollar} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {PermissionHandler(permissions, "PayerUsers", "Read") && (
        <MenuItemLink
          to="/payer_users"
          primaryText="Płatnicy użytkownicy"
          leftIcon={<FontAwesomeIcon icon={faHandHoldingUsd} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {PermissionHandler(permissions, "PickupPoints", "Read") && (
        <MenuItemLink
          to="/pickup_points"
          primaryText="Punkty odbioru"
          leftIcon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {PermissionHandler(permissions, "Workers", "Read") && (
        <MenuItemLink
          to="/workers"
          primaryText="Pracownicy"
          leftIcon={<FontAwesomeIcon icon={faIdCardAlt} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {PermissionHandler(permissions, "Samples", "Read") && (
        <MenuItemLink
          to="/samples"
          primaryText="Próbki"
          leftIcon={<img src={podImage} height="25" />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          onMouseOver={(e) => {
            setPodImage("/podS_white.png");
          }}
          onMouseOut={(e) => {
            setPodImage("/podS.png");
          }}
        />
      )}
      {/* {PermissionHandler(permissions, "RefundCalc", "Read") && (
        <MenuItemLink
          to="/calculator"
          primaryText="Kalkulator refundacji"
          leftIcon={<FontAwesomeIcon icon={faCalculator} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )} */}
      {/* {PermissionHandler(permissions, "RefundCalc", "Read") && (
        <MenuItemLink
          to="/documents"
          primaryText="Dokumenty refundacji"
          leftIcon={<FontAwesomeIcon icon={faFileContract} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )} */}

      {PermissionHandler(permissions, "RefundMenu", "Read") && (
        <MenuItemLink
          to="/refundation-menu"
          primaryText="Refundacja"
          leftIcon={<FontAwesomeIcon icon={faReceipt} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {PermissionHandler(permissions, "Tutorials", "Read") && (
        <MenuItemLink
          to="/tutorials"
          primaryText="Szkolenia"
          leftIcon={<FontAwesomeIcon icon={faUserGraduate} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      <hr />
      {PermissionHandler(permissions, "PkUsers", "Read") && (
        <MenuItemLink
          to="/pk_users"
          primaryText="Użytkownicy panelu"
          leftIcon={<FontAwesomeIcon icon={faUsers} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {localStorage.getItem("notifyOther") == "true" && dataAlerts && (
        <>
          <Alerts style={{ marginTop: 10 }} data={dataAlerts} />
        </>
      )}
      {isXSmall && logout}
    </div>
  ) : null;
};

const Alerts = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //let alertsNotifications = [];
  props.data &&
    props.data.map((x) => {
      if (x.type != "success" || x.type != "danger") return;
      //if (x.hide == true)
      enqueueSnackbar(x.message, {
        variant: x.type == "danger" ? "error" : x.type,
        autoHideDuration: 10000,
        preventDuplicate: true,
      });
      // else
      //   enqueueSnackbar(x.message, {
      //     variant: x.type == "danger" ? "error" : x.type,
      //     autoHideDuration: null,
      //     preventDuplicate: true,
      //   });
      // return alertsNotifications.push(
      //   !x.hide && (
      //     <div class={`alert alert-${x.type}`} role="alert">
      //       {x.message}
      //     </div>
      //   )
      // );
    });
  localStorage.setItem("notifyOther", false);
  return null;
  // return (
  //   <div class={props.class} style={props.style}>
  //     {alertsNotifications}
  //   </div>
  // );
};

export default withRouter(Menu);
