import * as React from "react";
import { cloneElement } from "react";
import { Edit, SimpleForm, FormDataConsumer } from "react-admin";

import {
  CustomTextInput,
  CustomDateInput,
  CustomNumberInput,
} from "../CustomInputs/CustomInputs";

import { required, email } from "react-admin";

import authProvider from "../../config/authProvider";

import {
  PostBottomToolbarAdd,
  PostBottomToolbarEdit,
} from "../CustomElements/PostBottomToolbars";

import { useSnackbar } from "notistack";

const requiredField = required("Pole wymagane");

// const transform = (data) => ({
//   ...data,
//   courseId: parseInt(data.courseId),
// });

export default (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const TutorialStatus = (startDate, endDate) => {
    let actualDate = new Date();
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    if (actualDate < startDate) return "Zaplanowane";
    if (actualDate >= startDate && actualDate <= endDate) return "Aktywne";
    if (actualDate > endDate) return "Zakończone";
    else return "Błąd";
  };

  const validateDates = (values) => {
    const errors = {};

    if (new Date(values.startDate) <= new Date())
      errors.startDate =
        "Data startu ma być przyszła (minimum 1 dzień w przód)";
    if (new Date(values.endDate) <= new Date(values.startDate))
      errors.endDate = "Data końcu ma być większa od daty startu";

    if (new Date(values.startDiscountDate) <= new Date())
      errors.startDiscountDate =
        "Data początku ma być przyszła (minimum 1 dzień w przód)";
    if (new Date(values.endDiscountDate) <= new Date(values.startDiscountDate))
      errors.endDiscountDate = "Data końcu ma być większa od daty startu";

    return errors;
  };

  const CustomTitle = (props) => `Szkolenie ${props.record.name}`;

  return (
    <Edit
      {...props}
      actions={null}
      title={<CustomTitle />}
      onFailure={(err) =>
        err.response.json().then((x) =>
          enqueueSnackbar(x.error, {
            variant: "error",
            autoHideDuration: 10000,
            preventDuplicate: true,
          })
        )
      }
      //transform={transform}
    >
      <SimpleForm
        toolbar={<PostBottomToolbarEdit saveRedirectPath="/tutorials" />}
        validate={validateDates}
      >
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th class="col-form-label" style={{ width: "30%" }}>
                    Nazwa szkolenia
                  </th>
                  <th class="col-md">
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>

                <tr>
                  <th class="col-form-label" style={{ width: "30%" }}>
                    ID szkolenia
                  </th>
                  <th class="col-md">
                    <CustomTextInput
                      source="courseId"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <td colSpan={2} style={{ borderTop: "0" }}>
                    <strong>PRZYKŁAD:</strong>
                    <br />
                    Id szkolenia jest{" "}
                    <strong>b2b479a0-0a4e-490c-8e36-eb46e1be9a25</strong> z
                    przykłdowego linku:{" "}
                    https://tzmo.e-lea.com/anonymous/taking_courses/courses/
                    <strong>b2b479a0-0a4e-490c-8e36-eb46e1be9a25</strong>
                    ?group_id=test
                  </td>
                </tr>

                <tr>
                  <th class="col-form-label">Data startu</th>
                  <th class="col-md">
                    <CustomDateInput
                      source="startDate"
                      validate={requiredField}
                      formatValue={true}
                    />
                  </th>
                </tr>
                <tr>
                  <th class="col-form-label">Data końca</th>
                  <th class="col-md">
                    <CustomDateInput
                      source="endDate"
                      validate={requiredField}
                      formatValue={true}
                    />
                  </th>
                </tr>

                <tr>
                  <th class="col-form-label">Początek okresu rabatu</th>
                  <th class="col-md">
                    <CustomDateInput
                      source="startDiscountDate"
                      validate={requiredField}
                      formatValue={true}
                    />
                  </th>
                </tr>
                <tr>
                  <th class="col-form-label">Koniec okresu rabatu</th>
                  <th class="col-md">
                    <CustomDateInput
                      source="endDiscountDate"
                      validate={requiredField}
                      formatValue={true}
                    />
                  </th>
                </tr>

                <tr>
                  <th class="col-form-label" style={{ width: "30%" }}>
                    Status
                  </th>
                  <th class="col-md">
                    <FormDataConsumer>
                      {({ formData, ...rest }) =>
                        formData.startDate &&
                        formData.endDate &&
                        TutorialStatus(formData.startDate, formData.endDate)
                      }
                    </FormDataConsumer>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
