import * as React from "react";
import { cloneElement } from "react";
import { Create, SimpleForm } from "react-admin";

import { CustomTextInput } from "../CustomInputs/CustomInputs";

import { required, email } from "react-admin";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");

const transform = (data) => ({
  //for maxorders - transform type from string to int
  ...data,
  maxorder: parseInt(data.maxorder),
});

export default (props) => {
  return (
    <Create
      {...props}
      actions={null}
      title={`Dodaj próbkę`}
      transform={transform}
    >
      <SimpleForm
        toolbar={<PostBottomToolbarAdd saveRedirectPath="/samples" />}
      >
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th class="col-form-label" style={{ width: "30%" }}>
                    Nazwa
                  </th>
                  <th class="col-md">
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>

                <tr>
                  <th class="col-form-label">SKU</th>
                  <th class="col-md">
                    <CustomTextInput source="sku" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th class="col-form-label">
                    Maksymanlna ilość do zamówienia
                  </th>
                  <th class="col-md">
                    <CustomTextInput
                      source="maxorder"
                      type={"number"}
                      validate={requiredField}
                    />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
