import decodeJwt from "jwt-decode";

export default {
  login: ({username, password}) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/authentication_token`,
      {
        method: "POST",
        body: JSON.stringify({email: username, password}),
        headers: new Headers({"Content-Type": "application/json"}),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({token}) => {
        const decodedToken = decodeJwt(token);
        //var md5 = md5(username+'d4fnmuwp4unfp4398jd');
        // var xhr = new XMLHttpRequest();
        // xhr.open(
        //   "GET",
        //   //`${process.env.PRESTA_URL}/superlogin.php?hash=`+md5+"&email=" +
        //   "https://zakupy.tzmoonline.pl/superlogin.php?hash=d4fnmuwp4unfp4398jd&email=" +
        //   username +
        //   "&token=" +
        //   token +
        //   "&timestamp=" +
        //   Date.now(),
        //   true
        // );
        // xhr.withCredentials = true;
        // xhr.send(null);

        localStorage.setItem("token", token);
        localStorage.setItem("permissions", decodedToken.roles);
      });
  },
  loginByHash: ({user_id, hash}) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/get_by_token`,
      {
        method: "POST",
        body: JSON.stringify({user_id: user_id, hash: hash}),
        headers: new Headers({"Content-Type": "application/json"}),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({token, username}) => {
        const decodedToken = decodeJwt(token);

        var xhr = new XMLHttpRequest();
        xhr.open(
          "GET",
          "https://zakupy.tzmoonline.pl/superlogin.php?hash=d4fnmuwp4unfp4398jd&email=" +
          username +
          "&token=" +
          token,
          true
        );
        xhr.withCredentials = true;
        xhr.send(null);

        localStorage.setItem("token", token);
        localStorage.setItem("permissions", decodedToken.roles);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("notify");
    localStorage.removeItem("notifyOther");
    localStorage.removeItem("hideBell");
    return Promise.resolve();
  },
  // register: ({payerData, users}) => {
  //   const request = new Request(
  //     `${process.env.REACT_APP_API_ENTRYPOINT}/payer_create`,
  //     {
  //       method: "POST",
  //       body: JSON.stringify({payerData, users}),
  //       headers: new Headers({"Content-Type": "application/json"}),
  //     }
  //   );
  //   return fetch(request)
  //     .then((response) => {
  //       if (response.status < 200 || response.status >= 300) {
  //         throw new Error(response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then(() => {
  //       return Promise.resolve();
  //     });
  // },
  changePassword: ({password, password2}) => {
    var token = localStorage.getItem("token");
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Content-Type", "application/json");

    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/pk_users/change_password`,
      {
        method: "POST",
        body: JSON.stringify({password: password, password2: password2}),
        headers: headers,
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        // https://stackoverflow.com/questions/53511974/javascript-fetch-failed-to-execute-json-on-response-body-stream-is-locked
        //console.log(response.json());
        //console.log(response.text());
        return response.json();
      })
      // .then(() => {
      //   return Promise.resolve();
      // })
      ;
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    if (isPublicUrl(window.location.hash.split("?")[0])) {
      return Promise.resolve();
    }
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  hasRole: (roles) => {
    if (isPublicUrl(window.location.hash.split("?")[0])) {
      return true;
    }
    const userroles = localStorage.getItem("permissions");
    return roles.some((role) => userroles && userroles.split(",").includes(role));
  },
  getPermissions: () => {
    if (isPublicUrl(window.location.hash.split("?")[0])) {
      return Promise.resolve();
    }

    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

function isPublicUrl(hash) {
  const publicUrls = [
    //"#/singin",
    "#/reset_password",
    "#/login_hash",
    //'#/login',
  ];
  return publicUrls.includes(hash) ? true : false;
}
