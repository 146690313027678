import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { usePermissions } from "react-admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faFileContract,
  faCommentDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import PermissionHandler from "../../config/PermissionHandler";

const menuElementContainer = {
  display: "flex",
  width: "100%",
  minWidth: 250,
  minHeight: 200,
  maxHeight: 100,
  margin: 10,
  padding: 10,
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2em",
  textDecoration: "none",
};

const fontStyle = {
  padding: "2%",
  color: "#d4731c",
  textTransform: "normal",
  textAlign: "center",
  fontSize: "1rem",
};

export default (props) => {
  const { permissions } = usePermissions();
  const refundPriceListUrl = process.env.REACT_APP_REFUND_PRICE_LIST_URL;

  return (
    <Card>
      <CardContent>
        <div class="page-content m-2">
          <div class="row">
            {PermissionHandler(permissions, "RefundCalc", "Read") && (
              <div class="col">
                <Link
                  to="/calculator"
                  style={menuElementContainer}
                  className="dash-card"
                >
                  <FontAwesomeIcon icon={faCalculator} inverse />
                  <span style={fontStyle}>Kalkulator refundacji</span>
                </Link>
              </div>
            )}
            {PermissionHandler(permissions, "RefundUpload", "Read") && (
              <div class="col">
                <Link
                  to="/documents"
                  style={menuElementContainer}
                  className="dash-card"
                >
                  <FontAwesomeIcon icon={faFileContract} inverse />
                  <span style={fontStyle}>Dokumenty refundacji</span>
                </Link>
              </div>
            )}
            <div class="col">
              <a
                href={refundPriceListUrl}
                target="_blank"
                style={menuElementContainer}
                className="dash-card"
              >
                <FontAwesomeIcon icon={faCommentDollar} inverse />
                <span style={fontStyle}>Cennik refundacyjny</span>
              </a>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
