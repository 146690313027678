import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useQuery, Loading, Error, usePermissions } from "react-admin";
import PermissionHandler from "../../config/PermissionHandler";

export default (props) => {
  const { permissions } = usePermissions();
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "users",
    payload: { id: "/pk_users/messages" },
  });

  useEffect(() => {
    if (localStorage.getItem("hideBell") != "true") {
      localStorage.setItem("hideBell", "true");
    }
  }, []);

  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser,
  } = useQuery({
    type: "getOne",
    payload: { id: `pk_users/me` },
  });

  if (loading || loadingUser) return <Loading />;
  if (error) return <Error />;

  const getNotificationClass = (type) => {
    switch (type) {
      case "info":
        return "alert alert-primary";
      case "warning":
        return "alert alert-warning";
      case "danger":
        return "alert alert-danger";
      case "success":
        return "alert alert-success";
      case "secondary":
        return "alert alert-secondary";
      case "primary":
        return "alert alert-primary";
    }
  };

  const alerts = data?.map((notification) => (
    <div class={getNotificationClass(notification.type)} role="alert">
      {notification.message}
    </div>
  ));

  PermissionHandler(permissions, "Dashboard", "CanBuy") &&
    alerts.push(
      <div class="alert alert-primary" role="alert">
        NOWOŚĆ! Duplikuj swoje stare zamówienia. Odwiedź historię zamówień i
        dodawaj wcześniej zamawiane produkty do koszyka.
      </div>
    );

  if (dataUser?.isBlocked)
    alerts.push(
      <div class="alert alert-danger" role="alert">
        Uwaga, posiadasz zaległe płatności. Obecnie składanie zamówień nie jest
        możliwe. Ureguluj zaznaczone dokumenty lub skontaktuj się z
        przedstawicielem TZMO
      </div>
    );

  return (
    <Card>
      <CardContent>
        <div class="page-content m-2">
          {alerts && alerts?.length > 0 ? alerts : "Brak komunikatów"}
        </div>
      </CardContent>
    </Card>
  );
};
