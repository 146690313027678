import React, { Component, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import authProvider from "../../config/authProvider";

export default class HashLogin extends Component {

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.hash);
    const user_id = urlParams.get('user_id');
    const hash = urlParams.get('hash');

    this.state = {
      redirect: false
    }

    authProvider.loginByHash({ user_id, hash }).then(
      (response) => {
        this.setState({ redirect: true });
      },
      (error) => {

      }
    );
  }


  render() {
    const { redirect } = this.state;
    if (this.state.redirect) {
      return <Redirect to='/mydata' />;
    }

    return (
      <div>Przekierowanie</div>
    );
  }
}
