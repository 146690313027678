import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  ListButton,
  Title,
  useGetList,
  usePermissions,
  useQuery,
  useLogout,
} from "react-admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../CustomElements/ConfirmModal";
import authProvider from "../../config/authProvider";

import { useSnackbar } from "notistack";

import PermissionHandler from "../../config/PermissionHandler";

export default function (props) {
  const { permissions } = usePermissions();
  const logout = useLogout();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [passwdError, setPasswdError] = useState("");

  const [show, setShow] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [inputs, setInputs] = useState({
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    originId: undefined,
    payerCity: null,
    payerHash: null,
    payerName: null,
    payerNip: null,
    payerNumber: null,
    payerParent: null,
    payerPostCode: null,
    payerStreet: null,
    payerVoivodeship: null,
    phone: null,
  });
  const onChangeForField = ({ target }) =>
    setInputs({ ...inputs, [target.name]: target.value });

  useEffect(() => {
    if (data != undefined && voivodeships.data != undefined) {
      //console.log(voivodeships.data[data.payerVoivodeship].name);
      setInputs({
        ...inputs,
        email: data.email,
        firstName: data.firstName,
        id: data.id,
        lastName: data.lastName,
        originId: data.originId,
        payerCity: data.payerCity,
        payerHash: data.payerHash,
        payerName: data.payerName,
        payerNip: data.payerNip,
        payerNumber: data.payerNumber,
        payerParent: data.payerParent,
        payerPostCode: data.payerPostCode,
        payerStreet: data.payerStreet,
        payerVoivodeship:
          voivodeships.data[data.payerVoivodeship] &&
          voivodeships.data[data.payerVoivodeship].name, //data.payerVoivodeship,
        phone: data.phone,
      });
    }
  }, [isFetched]);

  /////// pk_users/me fetching
  const voivodeships = useGetList(
    "voivodeships",
    { page: 1, perPage: 50 },
    { field: "name", order: "DESC" }
  );
  const { data, loading, error } = useQuery({
    type: "getOne",
    payload: { id: `pk_users/me` },
  });
  if (data != undefined && voivodeships.data != undefined && isFetched == false)
    setIsFetched(true);

  /////// pk_users/me fetching end

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      inputs.password == inputs.password2 &&
      inputs.password &&
      inputs.password.length > 3
    ) {
      setPasswdError("");
      const password = inputs.password;
      const password2 = inputs.password2;
      authProvider.changePassword({ password, password2 }).then(
        (response) => {        
          enqueueSnackbar(response.message, {
            variant: "info",
            autoHideDuration: 10000,
          });
          logout().then(() => {
            enqueueSnackbar("Proszę zalogować się ponownie", {
              variant: "warning",
              autoHideDuration: 10000,
            });
          });
        },
        (error) => {
          const resMessage =
            (error.response && error.response.message) ||
            error.message ||
            error.toString();
          enqueueSnackbar(resMessage, {
            variant: "warning",
            autoHideDuration: 10000,
          });
        }
      );
    } else if (inputs.password !== inputs.password2) {
      setPasswdError("Hasła różnią się");
    } else if (inputs.password && inputs.password.length < 3) {
      setPasswdError("Długość hasła jest zbyt mała");
    } else if (!inputs.password && !inputs.password2) {
      setPasswdError("");
    }
  };

  return (
    <Card>
      <Title title="Moje dane" />
      <CardContent>
        <ConfirmModal //mine - means good :)
          isOpen={show}
          title="Odpiąć całego płatnika od sieci aptek?"
          content="Na pewno?"
          confirm="Tak"
          cancel="Nie"
          onConfirm={() => {
            hideModal();
            props.history.push("/payers/remove_hash");
          }}
          onClose={() => {
            hideModal();
          }}
        />
        <form onSubmit={handleSubmit}>
          <div className="d-flex w-100 justify-content-around">
            {PermissionHandler(permissions, "MyData", "PayerDataFV") && (
              /* col1 */
              <div class="col-xs-6">
                <h1 class="text-center">
                  <FontAwesomeIcon icon={faHome} />
                  <span class="badge badge-danger">Dane płatnika - FV</span>
                </h1>
                <div class="table">
                  <table class="table table-striped">
                    <tr>
                      <th class="col-form-label">Nazwa</th>
                      <td class="col-md">
                        <span>{inputs.payerName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">NIP</th>
                      <td class="col-md">
                        <span>{inputs.payerNip}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Miejscowość</th>
                      <td class="col-md">
                        <span>{inputs.payerCity}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Kod pocztowy</th>
                      <td class="col-md">
                        <span>{inputs.payerPostCode}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Ulica</th>
                      <td class="col-md">
                        <span>{inputs.payerStreet}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Województwo</th>
                      <td class="col-md">
                        <span>{inputs.payerVoivodeship}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Numer klienta</th>
                      <td class="col-md">
                        <span>{inputs.payerNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Numer podpięcia do sieci</th>
                      <td class="col-md">
                        <span>{inputs.payerHash}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="col-form-label">Połączony z siecią</th>
                      <td class="col-md">
                        <span>{inputs.payerParent}</span>
                      </td>
                      {/*<button*/}
                      {/*  type="button"*/}
                      {/*  class="btn btn-danger"*/}
                      {/*  onClick={showModal}*/}
                      {/*>*/}
                      {/*  odepnij*/}
                      {/*</button>*/}
                    </tr>
                  </table>
                </div>
              </div>
            )}

            {/* col2 */}
            <div class="col-xs-6">
              <h1 class="text-center">
                <FontAwesomeIcon icon={faUserCircle} />
                <span class="badge badge-primary">Dane kontaktowe</span>
              </h1>
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <th class="col-form-label">Imię</th>
                    <td class="col-md">
                      <span>{inputs.firstName}</span>
                      {/*<input*/}
                      {/*  name="imie"*/}
                      {/*  type="text"*/}
                      {/*  class="form-control"*/}
                      {/*  required*/}
                      {/*  value={inputs.firstName}*/}
                      {/*  // onChange={onChangeForField}*/}
                      {/*/>*/}
                    </td>
                  </tr>
                  <tr>
                    <th class="col-form-label">Nazwisko</th>
                    <td class="col-md">
                      <span>{inputs.lastName}</span>
                      {/*<input*/}
                      {/*  name="nazwisko"*/}
                      {/*  type="text"*/}
                      {/*  class="form-control"*/}
                      {/*  required*/}
                      {/*  value={inputs.lastName}*/}
                      {/*  // onChange={onChangeForField}*/}
                      {/*/>*/}
                    </td>
                  </tr>
                  <tr>
                    <th class="col-form-label">Adres e-mail</th>
                    <td class="col-md">
                      <span>{inputs.email}</span>
                    </td>
                  </tr>
                  <tr>
                    <th class="col-form-label">Numer tel.</th>
                    <td class="col-md">
                      <span>{inputs.phone}</span>
                      {/*<input*/}
                      {/*  name="numerTel"*/}
                      {/*  type="text"*/}
                      {/*  class="form-control"*/}
                      {/*  required*/}
                      {/*  value={inputs.phone}*/}
                      {/* // onChange={onChangeForField}*/}
                      {/*/>*/}
                      {/*/>*/}
                    </td>
                  </tr>
                  <br />
                  <br />
                  {/*<tr>*/}
                  {/*    <th class="col-form-label">Aktulane hasło</th>*/}
                  {/*    <td class="col-md">*/}
                  {/*      <input*/}
                  {/*        name="aHaslo"*/}
                  {/*        type="password"*/}
                  {/*        class="form-control"*/}
                  {/*        value={inputs.aHaslo}*/}
                  {/*        onChange={onChangeForField}*/}
                  {/*        required={nHaslo}*/}
                  {/*      />*/}
                  {/*    </td>*/}
                  {/*  </tr>*/}
                  <tr>
                    <th class="col-form-label">Nowe hasło</th>
                    <td class="col-md">
                      <input
                        type="password"
                        name={"password"}
                        className={
                          passwdError == ""
                            ? "form-control"
                            : "form-control is-invalid"
                        }
                        onChange={onChangeForField}
                        required={inputs.password2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="col-form-label">Powtórz hasło</th>
                    <td className="col-md">
                      <input
                        type="password"
                        name={"password2"}
                        className={
                          passwdError == ""
                            ? "form-control"
                            : "form-control is-invalid"
                        }
                        onChange={onChangeForField}
                        required={inputs.password}
                      />
                      {passwdError != "" && (
                        <span class="text-danger">{passwdError}</span>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              type="submit"
              class="btn btn-success btn-lg"
              value="Zapisz"
            />
            <ListButton
              label="Anuluj"
              class={"btn btn-warning btn-lg"}
              basePath={props.basePath}
            />
          </div>
        </form>
      </CardContent>
    </Card>
  );
}
