import * as React from "react";

export default class extends React.Component {
  handleClose = (e) => {
    this.props.onClose();
    e.stopPropagation();
  };

  render() {
    return (
      <div
        class="modal"
        style={{ display: this.props.isOpen ? "block" : "none" }}
      >
        <div
          class="bg-secondary"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0.7,
          }}
          onClick={this.handleClose}
        />
        <div class="modal-dialog" style={{ top: "25%" }}>
          <div class="modal-content">
            <div class="modal-header">
              {(this.props.title) && (<h5 class="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>)}
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {(this.props.content) && (<div class="modal-body">{this.props.content}</div>)}
            <div class="modal-footer">
              {(this.props.cancel) && (<button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                onClick={this.handleClose}
              >
                {this.props.cancel}
              </button>)}
              {(this.props.confirm) && (<button
                type="button"
                class="btn btn-danger"
                onClick={this.props.onConfirm}
              >
                {this.props.confirm}
              </button>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
