const permissionsInfo = {
  Dashboard: {
    InfoRow: ["ROLE_MANAGER", "ROLE_WORKER"],
    ContactForm: ["ROLE_MANAGER", "ROLE_WORKER"],
    ComplaintRow: ["ROLE_PAYER_VIRTUAL", "ROLE_PAYER", "ROLE_MANAGER", "ROLE_WORKER"],
    //CanBuy: [ "ROLE_SUPER_ADMIN","ROLE_MANAGER", "ROLE_WORKER"],
    CanBuy: ["ROLE_MANAGER", "ROLE_WORKER"],
  },
  Invoices: {
    Create: [],
    Read: ["ROLE_MANAGER", "ROLE_PAYER_VIRTUAL", "ROLE_PAYER", "ROLE_WORKER"],
    Update: [],
    Delete: [],
    GetBalance: ["ROLE_PAYER_VIRTUAL", "ROLE_PAYER", "ROLE_MANAGER"],
    BalanceView: ["ROLE_PAYER"],
    GetDelayedInvoiceNumber: ["ROLE_MANAGER", "ROLE_PAYER_VIRTUAL"],
  },
  Orders: {
    Create: [],
    Read: ["ROLE_PAYER_VIRTUAL", "ROLE_PAYER", "ROLE_MANAGER", "ROLE_WORKER"],
    Update: [],
    Delete: [],
    GetDiscount: ["ROLE_PAYER_VIRTUAL", "ROLE_PAYER"],
  },
  PickupPoints: {
    Create: [],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER", "ROLE_PAYER_VIRTUAL", "ROLE_WORKER", "ROLE_MANAGER"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: [],
    NumberStatusEdit: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Workers: {
    Create: ["ROLE_MANAGER", "ROLE_PAYER", "ROLE_PAYER_VIRTUAL"],
    Read: ["ROLE_MANAGER", "ROLE_PAYER", "ROLE_PAYER_VIRTUAL"],
    Update: ["ROLE_MANAGER", "ROLE_PAYER", "ROLE_PAYER_VIRTUAL"],
    Delete: ["ROLE_MANAGER", "ROLE_PAYER", "ROLE_PAYER_VIRTUAL"],
  },
  Payers: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER_VIRTUAL", "ROLE_TRAINER"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER_VIRTUAL"],
    Delete: [],
  },
  PkUsers: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  PayerUsers: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER_VIRTUAL"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER_VIRTUAL"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER_VIRTUAL"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PAYER_VIRTUAL"],
  },
  PayerDiscounts: {
    Create: [],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: [],
  },
  Samples: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN"],
  },
  MyData: {
    PayerDataFV: ["ROLE_PAYER"],
  },
  Tutorials: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TRAINER"],
    Read: [
      "ROLE_SUPER_ADMIN",
      "ROLE_ADMIN",
      "ROLE_MANAGER",
      "ROLE_WORKER",
      "ROLE_TRAINER",
      "ROLE_PAYER",
      "ROLE_PAYER_VIRTUAL",
    ],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TRAINER"],
    Delete: ["ROLE_SUPER_ADMIN"],
    AdminViewRead: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_TRAINER"],
    WorkerViewRead: ["ROLE_MANAGER", "ROLE_WORKER", "ROLE_TRAINER"],
    PayerViewRead: ["ROLE_PAYER", "ROLE_PAYER_VIRTUAL"],
  },
  RefundMenu: {
    Create: [],
    Read: ["ROLE_MANAGER", "ROLE_WORKER", "ROLE_SUPER_ADMIN"],
    Update: [],
    Delete: [],
  },
  RefundCalc: {
    Create: [],
    Read: ["ROLE_MANAGER", "ROLE_WORKER", "ROLE_SUPER_ADMIN"],
    Update: [],
    Delete: [],
  },
  RefundUpload: {
    Create: [],
    Read: ["ROLE_MANAGER", "ROLE_WORKER", "ROLE_SUPER_ADMIN"],
    Update: [],
    Delete: [],
  },
  InfoPopup: {
    Create: [],
    Read: ["ROLE_MANAGER", "ROLE_PAYER_VIRTUAL", "ROLE_PAYER", "ROLE_SUPER_ADMIN"],
    Update: [],
    Delete: [],
  },
  Default: {
    default: [],
  },
};

const PermissionHandler = (
  permissions = null,
  moduleName = "Default",
  actionName = "default"
) => {
  let result = false;

  if (
    permissions &&
    moduleName &&
    actionName &&
    permissionsInfo.hasOwnProperty(moduleName)
  )
    permissions.split(",").map((x) => {
      if (permissionsInfo[moduleName][actionName])
        if (permissionsInfo[moduleName][actionName].includes(x)) result = true;
    });

  return result;
};

export default PermissionHandler;
