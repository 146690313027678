import * as React from "react";
import { addField } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import { CustomSelectInput } from "./CustomInputs";

const CustomNullableBooleanInput = ({
  input,
  source,
  reference,
  choices,
  meta: { touched, error },
}) => (
  <CustomSelectInput
    label="Status"
    errorText={touched && error}
    source={source}
    {...input}
    {...reference}
  >
    {/* <MenuItem value={true}>Aktywny</MenuItem>
    <MenuItem value={false}>Nieaktywny</MenuItem> */}
  </CustomSelectInput>
);

export default addField(CustomNullableBooleanInput);
