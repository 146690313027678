import React, { Component, useEffect } from "react";
import useState from "react-usestateref";

const Product = (props) => {
  const [choosedProductId, setChoosedProductId] = useState(null);
  const [cost, setCost, costRef] = useState(0);
  const [szt, setSzt, sztRef] = useState(0);
  const [wartosc, setWartosc] = useState(0);
  const [type, setType, typeRef] = useState(0);

  let Id = props.productId;

  const handleProductChange = (e) => {
    let tmp = props.ProductsInfo.find((el) => el["id"] == e.target.value);

    let tmpRefundLimit = 0;
    if (!tmp) tmp = 0;
    else {
      try {
        tmpRefundLimit = parseFloat(tmp?.refundLimit?.replace(",", "."));
        tmp = parseFloat(tmp?.priceGross?.replace(",", "."));
      } catch (error) {
        tmp = 0;
      }
    }

    let tmpFullCost = sztRef.current * tmp;
    setChoosedProductId(e.target.value);
    setCost(tmp);
    setWartosc(tmpFullCost);
    setType(tmpRefundLimit);

    props.UpdateSum(
      props.productId,
      sztRef.current,
      tmpFullCost,
      tmpRefundLimit,
      costRef.current
    );
  };

  const handleCountChange = (e) => {
    let tmpFullCost = e.target.value * costRef.current;
    setSzt(e.target.value);
    setWartosc(tmpFullCost);
    props.UpdateSum(
      props.productId,
      e.target.value,
      tmpFullCost,
      typeRef.current,
      costRef.current
    );
  };

  return (
    <div id={Id}>
      <div class="row align-items-center">
        <div class="col-6 col-md-5 form-group">
          <select
            class="browser-default custom-select"
            onChange={(e) => handleProductChange(e)}
          >
            <option selected value={null}>
              Wybierz produkt
            </option>
            {props?.ProductsInfo?.map((x) => (
              <option value={x.id}>
                {x.nfzCode} - {x.name} {x.size}
              </option>
            ))}
          </select>
        </div>

        <div class="col-6 col-md-2 form-group">
          <input
            type="text"
            class="form-control-plaintext"
            readonly
            value={cost != null ? parseFloat(cost).toFixed(2) + " zł" : null}
          />
        </div>

        <div class="col-6 col-md-2 form-group">
          <input
            type="number"
            class="form-control d-inline col-6"
            onChange={(e) => handleCountChange(e)}
            min={0}
            value={szt}
          />
          <div class="d-inline ml-1">szt.</div>
        </div>
        <div class="col-6 col-md-2 form-group">
          <input
            type="text"
            class="form-control-plaintext"
            readonly
            value={
              wartosc != null ? parseFloat(wartosc).toFixed(2) + " zł" : null
            }
          />
        </div>
        <div class="col-12 col-md-1 form-group">
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => {
              let isRemoved = props.RemoveFunc(props.keyProduct);
              if (isRemoved) props.UpdateSum(props.productId, 0, 0);
            }}
          >
            Usuń
          </button>
        </div>
      </div>
    </div>
  );
};

export default Product;
