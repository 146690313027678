import React, { Component, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import useState from "react-usestateref";

import Product from "./Product";

const Calculator = (props) => {
  const [productList, setProductList, productListRef] = useState([]);
  const [ProductsInfo, setProductsInfo, ProductsInfoRef] = useState([]);

  const KodSelect = [
    { key: "100", value: 100 },
    { key: "101", value: 101 },
  ];
  const UpraweniaSelect = [
    { key: "brak", value: 0 },
    { key: "IW", value: 1 },
    { key: "IB", value: 2 },
    { key: "OR", value: 3 },
    { key: "47.ZN", value: 4 },
    { key: "47.DN", value: 5 },
    { key: "47.ZDN", value: 6 },
    // { key: "cywilna niewidoma ofiara działań wojennych", value: 6 },
  ];
  const IloscMiesRefNaZlecSelect = [
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
  ];
  const IloscMiesWykSelect = [
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
  ];

  const [productKey, setProductKey] = useState(0);
  const [sumaSzt, setSumaSzt] = useState(0);
  const [sumaWartosc, setSumaWartosc] = useState(0);
  const [LimitRef, setLimitRef, LimitRefRef] = useState(0);
  const [Kod, setKod] = useState(0);
  const [kodTmp, setKodTmp] = useState(0);
  const [IloscMiesWyk, setIloscMiesWyk, IloscMiesWykRef] = useState(0);
  const [wykLimZaCzas, setWykLimZaCzas] = useState(0);
  const [IloscSztukNaMies, setIloscSztukNaMies, IloscSztukNaMiesRef] =
    useState(0);
  const [pozostalo, setPozostalo] = useState(0);
  const [KwotaRef, setKwotaRef] = useState(0);
  const [IloscMiesRefNaZlec, setIloscMiesRefNaZlec, IloscMiesRefNaZlecRef] =
    useState(0);
  const [Uprawenia, setUprawienia, UprawieniaRef] = useState(0);

  const [sztLimit, setSztLimit, sztLimitRef] = useState(9999999);

  let tmpKwotaRef = 0;

  const refLimitType0 = 1.7;
  const refLimitType1 = 1;

  const getProducts = async () => {
    const fetchHeaders = () => ({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    });

    const data = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/product_refunds?page=1&itemsPerPage=400`,
      {
        method: "GET",
        headers: new Headers(fetchHeaders()),
      }
    ).then((data) => {
      //console.log(data);
      try {
        data = data.json();
        return data;
      } catch (error) {
        return [];
      }
    });
    //console.log(data?.["hydra:member"]);
    setProductsInfo(data ? data["hydra:member"] && data["hydra:member"] : []);
    // return data ? data["hydra:member"] && data["hydra:member"] : [];
  };

  const AddProduct = () => {
    const tmpArr = Object.assign([], productListRef.current);
    tmpArr.push({ keyProduct: productKey, count: 0, value: 0, valueOne: 0 });
    setProductList(tmpArr);

    setProductKey(productKey + 1);
  };

  const RemoveProduct = (key) => {
    if (
      productListRef.current?.length > 1 &&
      productListRef.current?.filter(Boolean)?.length > 1
    ) {
      let tmp = productListRef.current;
      delete tmp[key];
      setProductList(tmp);
      return true;
    }
    return false;
  };

  const updateSum = (Key, Count, Value, RefundLimit, ValueOne) => {
    let tmp = productListRef.current.map((el) =>
      el.keyProduct == Key
        ? {
            ...el,
            count: Count,
            value: Value,
            refundLimit: RefundLimit,
            valueOne: ValueOne,
          }
        : el
    );

    setSumaSzt(
      tmp.length > 0
        ? tmp
            .map((e) => {
              return isNaN(e.count) || e.count == null || e.count == ""
                ? 0
                : parseInt(e.count);
            })
            .reduce((a, b) => {
              return parseInt(a + b);
            })
        : null
    );

    setSumaWartosc(
      tmp.length > 0
        ? tmp
            .map((e) => {
              return isNaN(e.value) || e.value == null
                ? 0
                : parseFloat(e.value);
            })
            .reduce((a, b) => {
              return parseFloat(a + b);
            })
        : null
    );

    setProductList(tmp);

    updateValues();
  };

  const updateValues = () => {
    let sumaRefundacji = 0;
    let sumaRazem = 0;
    let iloscProduktow = 0;
    //let sumaDoplata = 0;

    let iloscDostepnaNaMiesiac =
      IloscSztukNaMiesRef.current * IloscMiesWykRef.current;

    let limit = 0.7;

    if ([1, 2, 3].includes(parseInt(UprawieniaRef.current))) limit = 1;

    productListRef.current.map((prod, index) => {
      iloscProduktow += parseInt(prod?.count);
      console.log(prod);
      if (
        iloscProduktow <= iloscDostepnaNaMiesiac &&
        iloscDostepnaNaMiesiac != 0
      ) {
        if (prod?.valueOne >= prod?.refundLimit) {
          sumaRefundacji += prod?.count * (prod?.refundLimit * limit);
        } else {
          sumaRefundacji += prod?.count * (prod?.valueOne * limit); //prod?.count * (prod?.value * limit);
        }
      } else {
        if (prod?.valueOne >= prod?.refundLimit)
          sumaRefundacji = iloscDostepnaNaMiesiac * (prod?.refundLimit * limit);
        else
          sumaRefundacji += iloscDostepnaNaMiesiac * (prod?.valueOne * limit);
      }
      sumaRazem += prod?.count * prod?.value;
    });
    //sumaDoplata = sumaRazem - sumaRefundacji;
    setKwotaRef(sumaRefundacji);
  };

  useEffect(() => {
    getProducts();
    AddProduct();
  }, []);

  return (
    <Card>
      <Title title="Kalkulator refundacji" />
      <CardContent>
        <div class="container-fluid">
          <div class="jumbotron t-jumbotron">
            <div class="form-group row align-items-center">
              <div class="col-sm-3">Uprawienia</div>
              <div class="col-sm-5">
                <select
                  class="browser-default custom-select"
                  onChange={(e) => {
                    setUprawienia(e.target.value);
                    console.log(UprawieniaRef.current);
                    if ([1, 2, 3].includes(parseInt(UprawieniaRef.current)))
                      setSztLimit(90);
                    else setSztLimit(999999999);
                    updateValues();
                  }}
                >
                  <option selected value={0}>
                    Wybierz opcje
                  </option>
                  {UpraweniaSelect?.map((x) => (
                    <option value={x.value}>{x.key}</option>
                  ))}
                </select>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <div class="col-sm-3">Ilość sztuk na miesiąc</div>
              <div class="col-sm-5">
                <input
                  type="number"
                  class="form-control"
                  onChange={(e) => {
                    if (
                      e.target.value <= sztLimitRef.current &&
                      e.target.value >= 0
                    )
                      setIloscSztukNaMies(e.target.value);
                    else if (e.target.value > sztLimitRef.current)
                      setIloscSztukNaMies(sztLimitRef.current);
                    else if (e.target.value < 0) setIloscSztukNaMies(0);

                    updateValues();
                  }}
                  value={IloscSztukNaMies}
                  min={0}
                  max={sztLimitRef.current}
                />
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-xs-12 col-lg-8">
              <div class="jumbotron t-jumbotron blue">
                <div class="form-group row align-items-center">
                  <div class="col">Ilość miesięcy refundacji na zleceniu</div>
                  <div class="col">
                    <select
                      class="browser-default custom-select"
                      onChange={(e) => {
                        setIloscMiesRefNaZlec(e.target.value);
                        updateValues();
                      }}
                    >
                      <option selected value={0}>
                        Wybierz opcje
                      </option>
                      {IloscMiesRefNaZlecSelect?.map((x) => (
                        <option value={x.value}>{x.key}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div class="form-group row align-items-center">
                  <div class="col">Ile miesięcy chcesz wykorzystać?</div>
                  <div class="col">
                    <select
                      class="browser-default custom-select"
                      onChange={(e) => {
                        setIloscMiesWyk(e.target.value);
                        updateValues();
                      }}
                    >
                      <option selected value={0}>
                        Wybierz opcje
                      </option>
                      {IloscMiesWykSelect?.map(
                        (x) =>
                          IloscMiesRefNaZlec >= x.value && (
                            <option value={x.value}>{x.key}</option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-4">
              <div class="form-group align-items-center">
                <div class="col">Sztuk w miesiącach wykorzystanych</div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control-plaintext form-control-lg"
                    readonly
                    value={IloscSztukNaMies * IloscMiesWyk}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="h-25 d-inline-block"></div>

          {productListRef.current?.map((x, index) => {
            return (
              <Product
                keyProduct={index}
                productId={x?.keyProduct}
                ProductsInfo={ProductsInfo}
                RemoveFunc={RemoveProduct}
                UpdateSum={updateSum}
              />
            );
          })}
          <div class="row">
            <div class="col-sm">
              <button
                type="button"
                class="btn btn-success"
                onClick={AddProduct}
              >
                Dodaj produkt
              </button>
            </div>
          </div>
          <div class="h-25 d-inline-block"></div>
          <hr></hr>
          <div class="row">
            <div class="offset-lg-6 col-lg-6">
              <div class="row">
                <div class="big-text col-12">Suma</div>
                <div class="col-6">
                  <span class="ml-1 d-inline">szt.</span>
                  <input
                    type="text"
                    class="form-control-plaintext d-inline text-center auto-w"
                    readonly
                    value={sumaSzt}
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control-plaintext"
                    readonly
                    value={parseFloat(sumaWartosc).toFixed(2) + " zł"}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="sumup  jumbotron col-12  mt-5 offset-lg-6 col-lg-6">
            <div class="row">
              <div class="col-12 col-lg-6">Kwota dopłaty pacjenta</div>
              <div class="col-12 col-lg-6">
                <input
                  type="text"
                  class="form-control-plaintext text-lg-right"
                  readonly
                  value={
                    isFinite(KwotaRef)
                      ? parseFloat(
                          sumaWartosc - KwotaRef < 0
                            ? 0
                            : sumaWartosc - KwotaRef
                        ).toFixed(2) + " zł"
                      : (0).toFixed(2) + " zł"
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">Kwota refundacji</div>
              <div class="col-12 col-lg-6">
                <input
                  type="text"
                  class="form-control-plaintext text-lg-right"
                  readonly
                  value={
                    isFinite(KwotaRef)
                      ? parseFloat(KwotaRef).toFixed(2) + " zł"
                      : (0).toFixed(2) + " zł"
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">Razem</div>
              <div class="col-12 col-lg-6">
                <input
                  type="text"
                  class="form-control-plaintext text-lg-right"
                  readonly
                  value={parseFloat(sumaWartosc).toFixed(2) + " zł"}
                />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Calculator;

// class Product extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       choosedProductId: null,
//       cost: 0,
//       szt: 0,
//       wartosc: 0,
//     };
//     this.Id = this.props.productId;
//   }

//   handleProductChange = (e) => {
//     let tmp = this.props.ProductsInfo.find((el) => el["id"] == e.target.value);
//     //console.log(tmp);
//     if (tmp == undefined) tmp = 0;
//     else {
//       try {
//         tmp = parseFloat(tmp.priceGross.replace(",", "."));
//       } catch (error) {
//         tmp = 0;
//       }
//     }
//     let tmpFullCost = this.state.szt * tmp;
//     this.setState({
//       choosedProductId: e.target.value,
//       cost: tmp,
//       wartosc: tmpFullCost,
//     });
//     this.props.UpdateSum(this.props.productId, this.state.szt, tmpFullCost);
//   };

//   handleCountChange = (e) => {
//     let tmpFullCost = e.target.value * this.state.cost;
//     this.setState({ szt: e.target.value, wartosc: tmpFullCost });
//     this.props.UpdateSum(this.props.productId, e.target.value, tmpFullCost);
//   };

//   render() {
//     return (
//       <div id={this.Id}>
//         <div class="row align-items-center">
//           <div class="col-6 col-md-5 form-group">
//             {/* {this.Id} */}
//             <select
//               class="browser-default custom-select"
//               onChange={(e) => this.handleProductChange(e)}
//             >
//               <option selected value={null}>
//                 Wybierz produkt
//               </option>
//               {this.props.ProductsInfo.map((x) => (
//                 <option value={x.id}>
//                   {x.name} {x.size}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div class="col-6 col-md-2 form-group">
//             <input
//               type="text"
//               class="form-control-plaintext"
//               readonly
//               value={
//                 this.state.cost != null
//                   ? parseFloat(this.state.cost).toFixed(2) + " zł"
//                   : null
//               }
//             />
//           </div>

//           <div class="col-6 col-md-2 form-group">
//             <input
//               type="number"
//               class="form-control d-inline col-6"
//               onChange={(e) => this.handleCountChange(e)}
//               min={0}
//               value={this.state.szt}
//             />
//             <div class="d-inline ml-1">szt.</div>
//           </div>
//           <div class="col-6 col-md-2 form-group">
//             <input
//               type="text"
//               class="form-control-plaintext"
//               readonly
//               value={
//                 this.state.wartosc != null
//                   ? parseFloat(this.state.wartosc).toFixed(2) + " zł"
//                   : null
//               }
//             />
//           </div>
//           <div class="col-12 col-md-1 form-group">
//             <button
//               type="button"
//               class="btn btn-danger"
//               onClick={() => {
//                 let isRemoved = this.props.RemoveFunc(this.props.keyProduct);
//                 if (isRemoved) this.props.UpdateSum(this.props.productId, 0, 0);
//               }}
//             >
//               Usuń
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default class Register extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       productList: [],
//     };

//     this.Kod = 0;
//     this.Uprawenia = 0;
//     this.IloscSztukNaMies = 0;
//     this.IloscMiesRefNaZlec = 0;
//     this.IloscMiesWyk = 0;
//     this.LimitRef = 0;
//     this.SztukWMiesWyk = 0;
//     this.sumaSzt = 0;
//     this.sumaWartosc = 0;
//     this.pozostalo = 0;
//     this.wykLimZaCzas = 0;
//     this.kodTmp = 0;
//     this.KwotaRef = 0;

//     this.productKey = 0;

//     this.KodSelect = [
//       { key: "100", value: 100 },
//       { key: "101", value: 101 },
//     ];
//     this.UpraweniaSelect = [
//       { key: "brak", value: 0 },
//       { key: "47.ZN (osoby o znacznym stopniu niepełnosprawności)", value: 1 },
//       { key: "47.DN (osoby objęte ustawą za życiem)", value: 2 },
//       { key: "47IW (inwalida wojskowy)", value: 3 },
//       { key: "47IB (inwalida wojenny)", value: 4 },
//       { key: "47OR (osoba represjonowana)", value: 5 },
//       { key: "cywilna niewidoma ofiara działań wojennych", value: 6 },
//     ];
//     this.IloscMiesRefNaZlecSelect = [
//       { key: "1", value: 1 },
//       { key: "2", value: 2 },
//       { key: "3", value: 3 },
//       { key: "4", value: 4 },
//       { key: "5", value: 5 },
//       { key: "6", value: 6 },
//     ];
//     this.IloscMiesWykSelect = [
//       { key: "1", value: 1 },
//       { key: "2", value: 2 },
//       { key: "3", value: 3 },
//       { key: "4", value: 4 },
//       { key: "5", value: 5 },
//       { key: "6", value: 6 },
//     ];

//     this.ProductsInfo = [];
//   }

//   AddProduct = () => {
//     const tmpArr = Object.assign([], this.state.productList);
//     tmpArr.push({ keyProduct: this.productKey, count: 0, value: 0 });
//     this.setState({ productList: tmpArr });

//     this.productKey++;
//   };

//   RemoveProduct = (key) => {
//     if (
//       this.state.productList.length > 1 &&
//       this.state.productList.filter(Boolean).length > 1
//     ) {
//       let tmp = this.state.productList;
//       delete tmp[key];
//       this.setState({ productList: tmp });
//       return true;
//     }
//     return false;
//   };

//   updateSum = (Key, Count, Value) => {
//     let tmp = this.state.productList.map((el) =>
//       el.keyProduct == Key
//         ? {
//             ...el,
//             count: Count,
//             value: Value,
//           }
//         : el
//     );

//     this.sumaSzt =
//       tmp.length > 0
//         ? tmp
//             .map((e) => {
//               return isNaN(e.count) || e.count == null || e.count == ""
//                 ? 0
//                 : parseInt(e.count);
//             })
//             .reduce((a, b) => {
//               return parseInt(a + b);
//             })
//         : null;

//     this.sumaWartosc =
//       tmp.length > 0
//         ? tmp
//             .map((e) => {
//               return isNaN(e.value) || e.value == null
//                 ? 0
//                 : parseFloat(e.value);
//             })
//             .reduce((a, b) => {
//               return parseFloat(a + b);
//             })
//         : null;

//     this.setState({
//       productList: tmp,
//     });
//     this.updateValues();
//   };

//   updateValues = () => {
//     this.LimitRef =
//       this.Kod == 100
//         ? 77 * this.IloscMiesWyk
//         : this.Kod == 101
//         ? 90 * this.IloscMiesWyk
//         : 0;

//     this.wykLimZaCzas = Math.ceil(
//       parseFloat(this.sumaSzt) / parseFloat(this.IloscSztukNaMies)
//     );

//     this.pozostalo =
//       this.IloscSztukNaMies * this.IloscMiesRefNaZlec - this.sumaSzt;
//     this.pozostalo = this.pozostalo < 0 ? 0 : this.pozostalo;

//     //???
//     if (this.Kod == 100) {
//       if (this.wykLimZaCzas >= this.IloscMiesWyk) this.KwotaRef = this.LimitRef;
//       else this.KwotaRef = this.wykLimZaCzas * this.kodTmp;

//       if ([1, 2].includes(parseInt(this.Uprawenia))) {
//         this.LimitRef = 77;
//         if (this.sumaSzt > 90 && this.sumaSzt < 180) {
//           this.LimitRef *= 2;
//           this.KwotaRef = this.LimitRef;
//         } else if (this.sumaSzt > 180 && this.sumaSzt < 270) {
//           this.LimitRef *= 3;
//           this.KwotaRef = this.LimitRef;
//         } else if (this.sumaSzt > 270 && this.sumaSzt < 360) {
//           this.LimitRef *= 4;
//           this.KwotaRef = this.LimitRef;
//         } else if (this.sumaSzt > 360 && this.sumaSzt < 450) {
//           this.LimitRef *= 5;
//           this.KwotaRef = this.LimitRef;
//         } else if (this.sumaSzt > 450) {
//           this.LimitRef *= 6;
//           this.KwotaRef = this.LimitRef;
//         }
//       }
//     } else if (this.Kod == 101) {
//       this.LimitRef = 90;
//       this.KwotaRef = 63;
//       if (this.wykLimZaCzas >= this.IloscMiesWyk)
//         if ([3, 4, 5].includes(parseInt(this.Uprawenia)))
//           this.KwotaRef = this.LimitRef;
//       //else this.KwotaRef = this.LimitRef * 0.7;
//       if ([0, 1, 2].includes(parseInt(this.Uprawenia))) {
//         if (this.sumaSzt > 90 && this.sumaSzt < 180) {
//           this.LimitRef *= 2;
//           this.KwotaRef = 2 * 63;
//         } else if (this.sumaSzt > 180 && this.sumaSzt < 270) {
//           this.LimitRef *= 3;
//           this.KwotaRef = 3 * 63;
//         } else if (this.sumaSzt > 270 && this.sumaSzt < 360) {
//           this.LimitRef *= 4;
//           this.KwotaRef = 4 * 63;
//         } else if (this.sumaSzt > 360 && this.sumaSzt < 450) {
//           this.LimitRef *= 5;
//           this.KwotaRef = 5 * 63;
//         } else if (this.sumaSzt > 450) {
//           this.LimitRef *= 6;
//           this.KwotaRef = 6 * 63;
//         }
//       }
//       // } else if (this.Uprawenia == 0 || !this.Uprawenia) {
//       //   if (this.sumaWartosc < this.LimitRef) {
//       //     this.KwotaRef = 0.7 * this.sumaWartosc;
//       //   }
//       //   else this.KwotaRef = 90;
//       // }
//       //else this.KwotaRef = this.wykLimZaCzas * this.kodTmp;
//     } else this.KwotaRef = 0;

//     this.KwotaRef *= this.IloscMiesWyk;

//     if (this.KwotaRef > this.sumaWartosc) this.KwotaRef = this.sumaWartosc;

//     this.forceUpdate();
//   };

//   getProducts = async () => {
//     const fetchHeaders = () => ({
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     });

//     const data = await fetch(
//       `${process.env.REACT_APP_API_ENTRYPOINT}/product_refunds?page=1&itemsPerPage=400`,
//       {
//         method: "GET",
//         headers: new Headers(fetchHeaders()),
//       }
//     ).then((data) => {
//       //console.log(data);
//       try {
//         data = data.json();
//         return data;
//       } catch (error) {
//         return [];
//       }
//     });
//     //console.log(data ? data["hydra:member"] && data["hydra:member"] : []);
//     this.ProductsInfo = data
//       ? data["hydra:member"] && data["hydra:member"]
//       : [];
//     this.forceUpdate();
//     // return data ? data["hydra:member"] && data["hydra:member"] : [];
//   };

//   componentDidMount() {
//     this.getProducts();
//     this.AddProduct();
//   }

//   render() {
//     return (
//       <Card>
//         <Title title="Kalkulator refundacji" />
//         <CardContent>
//           <div class="container-fluid">
//             <div class="jumbotron t-jumbotron">
//               <div class="form-group row align-items-center">
//                 <div class="col-sm-3">Kod</div>
//                 <div class="col-sm-5">
//                   <select
//                     class="browser-default custom-select"
//                     onChange={(e) => {
//                       this.Kod = e.target.value;
//                       this.kodTmp =
//                         e.target.value == 100
//                           ? 77
//                           : e.target.value == 101
//                           ? 90
//                           : 0;
//                       this.updateValues();
//                     }}
//                   >
//                     <option selected value={0}>
//                       Wybierz opcje
//                     </option>
//                     {this.KodSelect.map((x) => (
//                       <option value={x.value}>{x.key}</option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//               <div class="form-group row align-items-center">
//                 <div class="col-sm-3">Uprawienia</div>
//                 <div class="col-sm-5">
//                   <select
//                     class="browser-default custom-select"
//                     onChange={(e) => {
//                       this.Uprawenia = e.target.value;
//                       this.updateValues();
//                     }}
//                   >
//                     <option selected value={0}>
//                       Wybierz opcje
//                     </option>
//                     {this.UpraweniaSelect.map((x) => (
//                       <option value={x.value}>{x.key}</option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//               <div class="form-group row align-items-center">
//                 <div class="col-sm-3">Ilość sztuk na miesiąc</div>
//                 <div class="col-sm-5">
//                   <input
//                     type="number"
//                     class="form-control"
//                     onChange={(e) => {
//                       this.IloscSztukNaMies = e.target.value;
//                       this.updateValues();
//                     }}
//                     value={this.IloscSztukNaMies}
//                     min={0}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div class="row align-items-center">
//               <div class="col-xs-12 col-lg-8">
//                 <div class="jumbotron t-jumbotron blue">
//                   <div class="form-group row align-items-center">
//                     <div class="col">Ilość miesięcy refundacji na zleceniu</div>
//                     <div class="col">
//                       <select
//                         class="browser-default custom-select"
//                         onChange={(e) => {
//                           this.IloscMiesRefNaZlec = e.target.value;
//                           this.updateValues();
//                         }}
//                       >
//                         <option selected value={0}>
//                           Wybierz opcje
//                         </option>
//                         {this.IloscMiesRefNaZlecSelect.map((x) => (
//                           <option value={x.value}>{x.key}</option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>

//                   <div class="form-group row align-items-center">
//                     <div class="col">Ilość miesięcy wykorzystanych</div>
//                     <div class="col">
//                       <select
//                         class="browser-default custom-select"
//                         onChange={(e) => {
//                           this.IloscMiesWyk = e.target.value;
//                           this.updateValues();
//                         }}
//                       >
//                         <option selected value={0}>
//                           Wybierz opcje
//                         </option>
//                         {this.IloscMiesWykSelect.map(
//                           (x) =>
//                             this.IloscMiesRefNaZlec >= x.value && (
//                               <option value={x.value}>{x.key}</option>
//                             )
//                         )}
//                       </select>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-xs-12 col-lg-4">
//                 <div class="form-group align-items-center">
//                   <div class="col">Limit refundacji</div>
//                   <div class="col">
//                     <input
//                       type="text"
//                       class="form-control-plaintext form-control-lg"
//                       readonly
//                       value={parseFloat(this.LimitRef).toFixed(2) + " zł"}
//                     />
//                   </div>
//                 </div>
//                 <div class="form-group align-items-center">
//                   <div class="col">Sztuk w miesiącach wykorzystanych</div>
//                   <div class="col">
//                     <input
//                       type="text"
//                       class="form-control-plaintext form-control-lg"
//                       readonly
//                       value={this.IloscSztukNaMies * this.IloscMiesWyk}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div class="h-25 d-inline-block"></div>

//             {/* <div class="row">
//               <div class="col">Produkt</div>
//               <div class="col">Cena</div>
//               <div class="col">szt.</div>
//               <div class="col">wartość</div>
//               <div class="col"></div>
//             </div> */}
//             {this.state.productList.map((x, index) => {
//               return (
//                 <Product
//                   keyProduct={index}
//                   productId={x.keyProduct}
//                   ProductsInfo={this.ProductsInfo}
//                   RemoveFunc={this.RemoveProduct}
//                   UpdateSum={this.updateSum}
//                 />
//               );
//             })}
//             <div class="row">
//               <div class="col-sm">
//                 <button
//                   type="button"
//                   class="btn btn-success"
//                   onClick={this.AddProduct}
//                 >
//                   Dodaj produkt
//                 </button>
//               </div>
//             </div>
//             <div class="h-25 d-inline-block"></div>
//             <hr></hr>
//             <div class="row">
//               <div class="offset-lg-6 col-lg-6">
//                 <div class="row">
//                   <div class="big-text col-12">Suma</div>
//                   <div class="col-6">
//                     <span class="ml-1 d-inline">szt.</span>
//                     <input
//                       type="text"
//                       class="form-control-plaintext d-inline text-center auto-w"
//                       readonly
//                       value={this.sumaSzt}
//                     />
//                   </div>
//                   <div class="col-6">
//                     <input
//                       type="text"
//                       class="form-control-plaintext"
//                       readonly
//                       value={parseFloat(this.sumaWartosc).toFixed(2) + " zł"}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* <div class="row">
//               <div class="col-sm-5"></div>
//               <div class="col-sm">pozostało</div>
//               <div class="col-sm">
//                 <input
//                   type="text"
//                   class="form-control"
//                   readonly
//                   value={this.pozostalo}
//                 />
//               </div>
//               <div class="col-sm"></div>
//               <div class="col-sm-1"></div>
//             </div>
//             <div class="row">
//               <div class="col-sm-5"></div>
//               <div class="col-sm">wykorzystano limit za czas</div>
//               <div class="col-sm">
//                 <input
//                   type="text"
//                   class="form-control"
//                   readonly
//                   value={isFinite(this.wykLimZaCzas) ? this.wykLimZaCzas : 0}
//                 />
//               </div>
//               <div class="col-sm"></div>
//               <div class="col-sm-1"></div>
//             </div>
//             <div class="row">
//               <div class="col-sm-5"></div>
//               <div class="col-sm">obliczenia ilościowe</div>
//               <div class="col-sm">
//                 <input
//                   type="text"
//                   class="form-control"
//                   readonly
//                   //value={this.state.sumaSzt}
//                 />
//               </div>
//               <div class="col-sm"></div>
//               <div class="col-sm-1"></div>
//             </div> */}

//             <br />
//             <div class="sumup  jumbotron col-12  mt-5 offset-lg-6 col-lg-6">
//               <div class="row">
//                 <div class="col-12 col-lg-6">Kwota dopłaty pacjenta</div>
//                 <div class="col-12 col-lg-6">
//                   <input
//                     type="text"
//                     class="form-control-plaintext text-lg-right"
//                     readonly
//                     value={
//                       isFinite(this.KwotaRef)
//                         ? parseFloat(
//                             this.sumaWartosc - this.KwotaRef < 0
//                               ? 0
//                               : this.sumaWartosc - this.KwotaRef
//                           ).toFixed(2) + " zł"
//                         : (0).toFixed(2) + " zł"
//                     }
//                   />
//                 </div>
//               </div>
//               <div class="row">
//                 <div class="col-12 col-lg-6">Kwota refundacji</div>
//                 <div class="col-12 col-lg-6">
//                   <input
//                     type="text"
//                     class="form-control-plaintext text-lg-right"
//                     readonly
//                     value={
//                       isFinite(this.KwotaRef)
//                         ? parseFloat(this.KwotaRef).toFixed(2) + " zł"
//                         : (0).toFixed(2) + " zł"
//                     }
//                   />
//                 </div>
//               </div>
//               <div class="row">
//                 <div class="col-12 col-lg-6">Razem</div>
//                 <div class="col-12 col-lg-6">
//                   <input
//                     type="text"
//                     class="form-control-plaintext text-lg-right"
//                     readonly
//                     value={parseFloat(this.sumaWartosc).toFixed(2) + " zł"}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </CardContent>
//       </Card>
//     );
//   }
// }
