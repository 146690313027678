import * as React from "react";
import {Filter, List, NumberField, ReferenceField, TextField,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

import {CustomTextInput} from "../CustomInputs/CustomInputs";

export default (props) => {
  return (
    <List
      {...props}
      empty={<EmptyList title="płatników" add={false} />}
      actions={
        <CustomActionBar
          isAddVisible={
            //   authProvider.hasRole([
            //   "ROLE_SUPER_ADMIN",
            //   "ROLE_ADMIN",
            //   "ROLE_PHARMACY_CHAIN",
            // ])
            PermissionHandler(props.permissions, "Payers", "Create")
          }
          // isPopupAddVisible={authProvider.hasRole([
          //   "ROLE_PAYER_VIRTUAL",
          //   "ROLE_PAYER",
          // ])}
        />
      }
      filters={
        <Filter className="d-flex flex-nowrap mt-0">
          <CustomTextInput alwaysOn label="Nazwa" source="name" />
          <CustomTextInput alwaysOn label="NIP" source="nip" />
        </Filter>
      }
      bulkActionButtons={false}
      title={`Płatnicy`}
    >
      <CustomDataGrid>
        <NumberField
          source="oracleId"
          label="Oracle Id"
          //sortable={false}
        />
        <TextField
          source="clientNumber"
          label="Numer klienta"
          //sortable={false}
        />
        <TextField
          source="name"
          label="Nazwa"
          //sortable={false}
        />
        <TextField
          source="nip"
          label="NIP"
          //sortable={false}
        />
        <TextField
          source="postCode"
          label="Kod pocztowy"
          //sortable={false}
        />
        <TextField
          source="street"
          label="Ulica"
          //sortable={false}
        />
        <TextField
          source="city"
          label="Miejscowość"
          //sortable={false}
        />

        <ReferenceField
          source="voivodeship"
          reference="voivodeships"
          label="Województwo"
          link={false}
          //sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>


        {PermissionHandler(props.permissions, "Payers", "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isDescriptionAvailable={PermissionHandler(
              props.permissions,
              "Payers",
              "Read"
            )}
            isDeleteAvailable={false}
          />
        )}

      </CustomDataGrid>
    </List>
  );
};
