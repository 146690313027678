import React, {useState} from "react";

const InfoPopup = (props) => {
  const [display, setDisplay] = useState(true);

  const onClosing = () => {
    setDisplay(false);
    localStorage.setItem("infoPopupShowed", true);
  };

  return (<div style={{
    display: display ? "block" : "none",
  }} className="InfoPopupBg"></div>) && (
    <div
      style={{
        display: display ? "block" : "none",
      }}
      className="InfoPopup"
    >
      <a
        href="#/invoices"
        onClick={() => {
          onClosing();
        }}
      >
        <img src={"/img/popup/BANER-FAKTURA-XML.png"} />
      </a>
      <img
        onClick={() => {
          onClosing();
        }}
        src={"/close.png"}
        height="25"
        className="InfoPopupClose"
      />
    </div>
  );
};

export default InfoPopup;
