import * as React from "react";
import {Button, DeleteButton, EditButton, Link, ShowButton,} from "react-admin";
import md5 from "md5";

const CustomEditButton = (props) => {
  return (
    <EditButton
      class={"btn btn-outline-success btn-sm"}
      {...props}
      label={"Edytuj"}
    />
  );
};

export const CustomActionButtonEdit = (props) => {
  return <CustomEditButton {...props} />;
};

const CustomDeleteButton = (props) => {
  return (
    <DeleteButton
      class={"btn btn-outline-danger btn-sm"}
      {...props}
      label={"Usuń"}
    />
  );
};

export const CustomActionButtonDelete = (props) => {
  return <CustomDeleteButton {...props} />;
};

const CustomOrdersDescriptionButton = ({ props, record }) => {
  return (
    <Button
      class={"btn btn-outline-secondary btn-sm"}
      {...props}
      label={"Podgląd zamówienia"}
      component={Link}
      to={{
        pathname: `/orders/${encodeURIComponent(record.orderId)}/show`,
        // search: stringify({
        //   page: 1,
        //   perPage: 25,
        //   filter: JSON.stringify({ orderId: record.orderId }),
        // }),
      }}
    />
  );
};
const CustomInvoiceDownloadButton = ({ props, record }) => {
  let id = record.id.replace('/invoices/','');
  let orderId = record.orderId.replace('/orders/','');
  let invoiceDate = Date.parse(record.dateSet);
  let now = Date.now();
  let moreThan4Month = (now - invoiceDate) > (1000 * 60 * 60 * 24 * 30 * 4);

  let hashMd5 = md5(id + process.env.REACT_APP_MD5_HASH + orderId);
  let url = `${process.env.REACT_APP_API_ENTRYPOINT}/ords/invoice-download/${id}/${hashMd5}`;

  return !moreThan4Month && (
    <Button
      class={"btn btn-outline-secondary btn-sm"}
      {...props}
      label={"Pobierz fakturę XML"}
      component={Link}
      onClick={() => {
        window.open(url, '_blank').focus();
      }}
    />
  );
};

const CustomAddToCartButton = ({ props, record }) => {
  return (
    <Button
      class={"btn btn-outline-secondary btn-sm"}
      {...props}
      label={"Dodaj do koszyka"}
      onClick={() => {
        console.log(record.id);
      }}
    />
  );
};

export const CustomShowButton = (props) => {
  return (
    <ShowButton
      class={"btn btn-outline-info btn-sm"}
      {...props}
      label={"Szczegóły"}
      //orderId={props.record.orderId}
    />
  );
};

export const CustomActionsButtons = (props) => {
  return (
    <div
      class={"btn-group"}
      // style={{
      //   display: "flex",
      //   flexDirection: "row",
      //   justifyContent: "space-around",
      //   alignItems: "center",
      // }}
    >
      {props.isEditAvailable ? (
        <CustomEditButton {...props} />
      ) : props.extraEditAvailableFunctionDependsOnRecord ? (
        props.extraEditAvailableFunctionDependsOnRecord(props.record) ? (
          <CustomEditButton {...props} />
        ) : null
      ) : null}
      {props.isDeleteAvailable && <CustomDeleteButton {...props} />}
      {props.isDescriptionAvailable && <CustomShowButton {...props} />}
      {props.isAddToCartAvailable && <CustomAddToCartButton {...props} />}
      {props.isCustomOrdersDescriptionButton && (
        <CustomOrdersDescriptionButton {...props} />
      )}
      {props.isCustomInvoiceDownloadButton && (
        <CustomInvoiceDownloadButton {...props} />
      )}
    </div>
  );
};
