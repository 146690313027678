import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Login as BaseLogin } from "react-admin";
import { useLogin, useNotify, useSafeSetState, useTranslate } from "ra-core";

import { useSnackbar } from "notistack";

// const globalTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: "#240a51"
//     }
//   }
// });

// const theme = createMuiTheme(
//   {
//     overrides: {
//       MuiButton: {

//         backgroundColor: globalTheme.palette.primary.main,

//       }
//     }
//   },
//   globalTheme
// );

const useStyles = makeStyles((theme) => ({
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
    width: "450px",
    margin: "auto",
  },
  input: {
    marginTop: "1em",
    width: "300px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
  },
  // button: {
  //   width: "50%",
  // },
  icon: {
    marginRight: theme.spacing(1),
  },
  loginLabel: {
    fontWeight: "bold",
    marginRight: "20px",
  },
}));

const styles = {
  login: {
    main: {
      background: "none",
      height: "auto",
      minHeight: "inherit",
    },
    card: {
      boxShadow: "none",
      marginTop: "0px",
      minWidth: "0px",
    },
    avatar: {
      display: "none",
    },
  },
};

// const Input = ({
//   meta: { touched, error },
//   input: inputProps,
//   name,
//   ...props
// }) => (
//   <CustomTextInput
//     error={!!(touched && error)}
//     helperText={touched && error}
//     {...inputProps}
//     {...props}
//     name={name}
//     fullWidth
//   />
// );

const Login = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        localStorage.setItem("notify", true);
        localStorage.setItem("notifyOther", true);
        setLoading(false);
        props.history.push("/#");
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Niepoprawna nazwa użytkownika lub hasło", {
          variant: "error",
          autoHideDuration: 10000,
        });
        // notify(
        //   typeof error === "string"
        //     ? error
        //     : typeof error === "undefined" || !error.message
        //     ? "ra.auth.sign_in_error"
        //     : error.message,
        //   "warning"
        // );
      });
  };

  return (
    <>
      <div className="thin-wrapper">
        <div className="inner login-panel">
          <div className="logo">
            <img src="logo.svg" />
          </div>
          <div className="title">
            <p>
              Platforma zakupowa produktów{" "}
              <span style={{ color: "#e18a3d" }}>Grupy</span> TZMO
              {/* tzm<span style={{ color: "#e18a3d" }}>O</span>nline */}
            </p>
          </div>
          <BaseLogin {...props}>
            <Form
              onSubmit={submit}
              validate={validate}
              // initialValues={{
              //   username: "admin@example.com",
              //   password: "admin",
              // }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  {/* <div className={classes.hint}>
              Wskazówka: admin@example.com / admin
            </div> */}
                  <div className="form-login">
                    <div className="form-group">
                      <label className={classes.loginLabel}>
                        {translate("ra.auth.username")}
                      </label>

                      <Field
                        autoFocus
                        id="username"
                        name="username"
                        //component={Input}
                        component="input"
                        className="form-control"
                        //label={translate("ra.auth.username")}
                        disabled={loading}
                      />
                    </div>
                    <div className="form-group">
                      <label className={classes.loginLabel}>
                        {translate("ra.auth.password")}
                      </label>

                      <Field
                        id="password"
                        name="password"
                        //component={Input}
                        component="input"
                        className="form-control"
                        //label={translate("ra.auth.password")}
                        type="password"
                        disabled={loading}
                        autoComplete="current-password"
                      />
                    </div>

                    <div className="d-flex flex-wrap">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disableElevation
                        disabled={loading}
                        className="primary full-width"
                      >
                        {loading && (
                          <CircularProgress
                            className={classes.icon}
                            size={18}
                            thickness={2}
                          />
                        )}
                        {translate("ra.auth.sign_in")}
                      </Button>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <p className="lost-paswd">
                        Jeśli nie pamiętasz hasła, skorzytaj z funkcji
                        resetowania hasła dostępnej{" "}
                        <a href="#/reset_password">tutaj</a>
                      </p>
                    </div>

                    <div
                      style={{
                        marginTop: 0,
                        textAlign: "center",
                        borderTop: "1px solid #ced4da",
                        paddingTop: 15,
                      }}
                    >
                      <p style={{ fontSize: 12 }}>
                        Jeśli masz problemy z logowaniem, skontaktuj się z nami:
                        <br />
                        56 612 38 00 wew. 1<br />
                        bok@tzmo-global.com
                        <br />
                        pracujemy w godzinach 8-16
                      </p>
                    </div>
                  </div>
                </form>
              )}
            />
          </BaseLogin>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default withStyles(styles.login)(Login);
