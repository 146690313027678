import React, {useEffect, useState} from "react";
import {
  AutocompleteInput,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  useDataProvider,
} from "react-admin";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import {CustomDateInput, CustomSelectInput, UniqueSelectInput,} from "../CustomInputs/CustomInputs";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";

import {Card, CardContent} from "@material-ui/core";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

export default (props) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [isFetched, setIsFetched] = useState(false);

  /////// payers/getdiscount fetching
  useEffect(() => {
    if (PermissionHandler(props.permissions, "Orders", "GetDiscount"))
      dataProvider.getOne("", {id: "payers/getdiscount"}).then(({data}) => {
        setData(data);
      });
  }, [isFetched || props.permissions]);
  if (data != undefined && isFetched == false) setIsFetched(true);
  /////// payers/getdiscount fetching end

  const pbSectionsCount = (currentQuarter) => {
    return (
      <div class="progress">
        <div
          className="progress-bar progress-bar-striped bg-info"
          role="progressbar"
          style={{
            height: "20px",
            width: ((currentQuarter / 3500) * 100) + "%",
          }}
        ></div>
      </div>
    );
  };

  return (
    <>
      {PermissionHandler(props.permissions, "Orders", "GetDiscount") && (
        <Card className="invoices-list-main">
          <CardContent className="d-flex w-100 justify-content-around invoices-list-tab">
            {/* col1 */}
            <div class="col-xs-12 col-md-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <th className="align-middle">Wielkość zamówień w poprzednim kwartale (na Aptekę):</th>
                    <td>
                    <span className=" number badge badge-success">
                      {data && `${data.lastQuarter} zł`}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th className="align-middle">
                      Uzyskany próg rabatowy Seni za poprzedni kwartał:
                    </th>
                    <td>
                    <span className=" number badge badge-primary">
                      {data && `${data.seniDiscount} %`}
                    </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            {/* col2 */}
            <div class="col-xs-12 col-md-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <th className="align-middle">
                      Obecny kwartał:
                    </th>
                    <td>
                    <span className=" number badge badge-danger">
                      {data && `${data.currentQuarter} zł`}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle" colSpan={2}>
                      <div>
                        <div className="d-flex w-100 justify-content-between">
                          <span>0%</span>
                          <span>1%</span>
                        </div>
                        {pbSectionsCount(data && data.currentQuarter)}
                        <div className="d-flex w-100 justify-content-between">
                          <span>0zł</span>
                          <span>3500zł</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>


            {/*<div className="d-flex flex-column">*/}
            {/*  <strong>Dodatkowe rabaty </strong>*/}
            {/*  <strong>Produkty Matopat: {data && data.matopatDiscount}</strong>*/}
            {/*</div>*/}
          </CardContent>
        </Card>
      )}

      <List
        {...props}
        empty={<EmptyList title="zamówień" add={false}/>}
        sort={{ field: 'date', order: 'DESC' }}
        actions={
          <CustomActionBar
            isAddVisible={false}
            //filtersDataFromRecords={filters}
          />
        }
        bulkActionButtons={false}
        title={"Zamówienia"}
        filters={
          <Filter className="d-flex flex-nowrap mt-5 mb-5">
            <CustomDateInput label="Data po" source="date[after]" alwaysOn/>
            <CustomDateInput
              label="Data przed"
              source="date[before]"
              alwaysOn
            />
            <ReferenceInput
              label="Źródło"
              source="zrzam"
              reference="orders"
              alwaysOn
            >
              <UniqueSelectInput
                optionId="zrzam"
                optionText="zrzam"
                source="zrzam"
                showErrors={false}
              />
            </ReferenceInput>
            <CustomSelectInput
              label="Status płatności"
              source="paymentStatus"
              choices={[
                {id: true, name: "Opłacone"},
                {id: false, name: "Nieopłacone"},
              ]}
              alwaysOn
            />
            <div style={{marginBottom: "-19px"}} alwaysOn>
              <label>
                <strong>Punkt odbioru:</strong>
              </label>
              <ReferenceInput
                //label="Punkt odbioru"
                label=""
                source="pickupPoint"
                reference="pickup_points"
                link={false}
                filterToQuery={(searchText) => ({internalname: searchText})}
                className="autocompleteinput"
                alwaysOn
                allowEmpty
              >
                <AutocompleteInput
                  optionText="internalname"
                />
              </ReferenceInput>
            </div>
            <ReferenceInput
              label="Status realizacji"
              source="status"
              reference="orders"
              alwaysOn
            >
              <UniqueSelectInput
                source="status"
                optionId="status"
                optionText="status"
                showErrors={false}
              />
            </ReferenceInput>
          </Filter>
        }
      >
        <CustomDataGrid
          isStatusColored={true}
          isFooterOrders={true}
          //footerOrdersData={data}
          footerOrdersData={{
            allNet: 100,
            allGross: 200,
            onlineNet: 100,
            onlineGross: 200,
            seniNet: 100,
            seniGross: 200,
            matopatNet: 100,
            matopatGross: 200,
            otherNet: 100,
            otherGross: 200,
          }}
        >
          <TextField source="nrZam" label="Numer zamówienia"/>
          <DateField source="date" label="Data wystawienia" showTime={true}/>
          <ReferenceField
            label="Punkt odbioru"
            source="pickupPoint"
            reference="pickup_points"
            link={false}
            //sortable={false}
          >
            <TextField source="internalname"/>
          </ReferenceField>
          <TextField source="status" label="Status realizacji"/>
          <CustomBooleanField
            label="Status płatności"
            source="paymentStatus"
            txtTrue="Opłacone"
            txtFalse="Nieopłacone"
          />
          <TextField source="net" label="Wartość netto w zł"/>
          <TextField source="gross" label="Wartość brutto w zł"/>
          <TextField source="zrzam" label="Źródło"/>

          <CustomActionsButtons
            label="Szczegóły zamówenia"
            isEditAvailable={PermissionHandler(props.permissions, "Orders", "Update")}
            isDeleteAvailable={PermissionHandler(props.permissions, "Orders", "Delete")}
            isDescriptionAvailable={true}
          />
        </CustomDataGrid>
      </List>
    </>
  );
};
