import React, {Children, cloneElement, isValidElement, useCallback,} from "react";
import PropTypes from "prop-types";
import {sanitizeListRestProps} from "ra-core";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import classnames from "classnames";

import decodeJwt from "jwt-decode";
import md5 from "md5";
import PermissionHandler from "../../config/PermissionHandler";

import {Button, DatagridBody, DatagridHeaderCell, DatagridLoading, PureDatagridBody} from "react-admin";

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
  },
  thead: {backgroundColor: "lightgrey"},
  tbody: {},
  headerRow: {},
  headerCell: {textAlign: "center", fontWeight: "bold"},
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: "pointer",
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {textAlign: "center"},
  expandHeader: {
    padding: 0,
    width: theme.spacing(6),
  },
  expandIconCell: {
    width: theme.spacing(6),
  },
  expandIcon: {
    padding: theme.spacing(1),
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: "rotate(0deg)",
  },
}));

function CustomDataGrid({classes: classesOverride, ...props}) {

  const classes = useStyles({classes: classesOverride});
  const {
    basePath,
    optimized = false,
    body = optimized ? <PureDatagridBody/> : <DatagridBody/>,
    children,
    className,
    currentSort,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    loading,
    loaded,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    selectedIds,
    setSort,
    size = "small",
    total,
    version,
    permissions = null,
    isFooterOrders = false,
    isFooterOrderOne = false,
    isAddToCartButton = false,
    footerOrdersData = null,
    footerOrderOneData = null,
    //fieldToSum,
    ...rest
  } = props;

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation();
      setSort(event.currentTarget.dataset.sort);
    },
    [setSort]
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        onSelect(ids.concat(selectedIds.filter((id) => !ids.includes(id))));
      } else {
        onSelect([]);
      }
    },
    [ids, onSelect, selectedIds]
  );

  /**
   * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
   * if loaded is true, the data for the list has at least been returned once by the dataProvider
   * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
   */
  if (loaded === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    );
  }

  /**
   * Once loaded, the data for the list may be empty. Instead of
   * displaying the table header with zero data rows,
   * the datagrid displays nothing in this case.
   */
  if (loaded && (ids.length === 0 || total === 0)) {
    return null;
  }

  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */
  return (
    <Table
      className={classnames(
        classes.table,
        className,
        "table-responsive table-striped"
      )}
      size={size}
      {...sanitizeListRestProps(rest)}
    >
      <TableHead >
        <TableRow>
          {expand && (
            <TableCell padding="none" className={classes.expandHeader}/>
          )}
          {hasBulkActions && (
            <TableCell padding="checkbox">
              <Checkbox
                className="select-all"
                color="primary"
                checked={
                  selectedIds.length > 0 &&
                  ids.length > 0 &&
                  ids.every((id) => selectedIds.includes(id))
                }
                onChange={handleSelectAll}
              />
            </TableCell>
          )}
          {Children.map(children, (field, index) =>
            isValidElement(field) && !field.props.isAddToCart ? (
              <DatagridHeaderCell
                className={classnames(classes.headerCell)}
                currentSort={currentSort}
                field={field}
                isSorting={
                  currentSort.field ===
                  (field.props.sortBy || field.props.source)
                }
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
              />
            ) : null
          )}
          {(isAddToCartButton && PermissionHandler(permissions, "Dashboard", "CanBuy")) ? (

              <Button
                class={"btn btn-outline-success btn-sm"}
                label={"Dodaj wszystkie pozycje do koszyka"}

                onClick={() => {
                  let token = localStorage.getItem("token");
                  let decodedToken = decodeJwt(token);
                  let username = decodedToken.username;
                  let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH);
                  let prestaUrl = process.env.REACT_APP_PRESTA_URL;

                  let skus = (ids.map((id) => data[id].sku+'|'+data[id].quantity).join(','));
                  let url = prestaUrl+"/superlogin.php?hash=" + hashMd5
                    + "&email=" + username + "&token=" +
                    token +
                    "&timestamp=" +
                    Date.now() +
                    "&redirect=true&product_sku=" +
                    skus;
                 window.open(url, '_blank').focus();
                }}
              />

          ) : null }
        </TableRow>
      </TableHead>
      {cloneElement(
        body,
        {
          basePath,
          className: classes.tbody,
          classes,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem,
          resource,
          rowStyle,
          selectedIds,
          version,
        },
        children
      )}
      {/* custom things */}
      {false && isFooterOrders && footerOrdersData && (
        <tfoot className="text-center">
        <>
          <tr>
            <td colSpan={3}/>
            <th colSpan={2}>SUMA Z WSZYSTKICH ŻRÓDEŁ</th>
            <td>{footerOrdersData && footerOrdersData.allNet}</td>
            <td>{footerOrdersData && footerOrdersData.allGross}</td>
          </tr>
          <br/>
          <tr>
            <td colSpan={3}/>
            <th colSpan={2}>Online</th>
            <td>{footerOrdersData && footerOrdersData.onlineNet}</td>
            <td>{footerOrdersData && footerOrdersData.onlineGross}</td>
          </tr>
          <tr>
            <td colSpan={3}/>
            <th colSpan={2}>Seni</th>
            <td>{footerOrdersData && footerOrdersData.seniNet}</td>
            <td>{footerOrdersData && footerOrdersData.seniGross}</td>
          </tr>
          <tr>
            <td colSpan={3}/>
            <th colSpan={2}>Matopat</th>
            <td>{footerOrdersData && footerOrdersData.matopatNet}</td>
            <td>{footerOrdersData && footerOrdersData.matopatGross}</td>
          </tr>
          <tr>
            <td colSpan={3}/>
            <th colSpan={2}>Pozostałe Marki</th>
            <td>{footerOrdersData && footerOrdersData.otherNet}</td>
            <td>{footerOrdersData && footerOrdersData.otherGross}</td>
          </tr>
        </>
        </tfoot>
      )}
      {isFooterOrderOne && footerOrderOneData && (
        <tfoot className="text-center">
        <>
          <tr>
            <td colSpan={children.length - 4}/>
            <th>Razem</th>
            <td>{footerOrderOneData && `${footerOrderOneData.allNet} zł`}</td>
            <td>
              {footerOrderOneData && `${footerOrderOneData.allGross} zł`}
            </td>

          </tr>
        </>
        </tfoot>
      )}
      {/* <tr>
            {Children.map(children, (field, index, record) => (
              <td key={index}>
                {ids
                  .map((id) => data[id][field.props.source]) //specify fields to sum (wartość netto / brutto) //fieldsToSum
                  .reduce((acc, curr) => acc + curr)}
              </td>
            ))}
          </tr> */}

    </Table>
  );
}

CustomDataGrid.propTypes = {
  basePath: PropTypes.string,
  body: PropTypes.element,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object.isRequired,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSort: PropTypes.func,
  total: PropTypes.number,
  version: PropTypes.number,
};

CustomDataGrid.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: [],
};

export default CustomDataGrid;
