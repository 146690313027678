import * as React from "react";
import {
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { CustomSelectInput } from "../CustomInputs/CustomInputs";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import { CustomActionsButtons } from "../CustomFields/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import CustomFiltersBar from "../CustomElements/CustomFiltersBar";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import authProvider from "../../config/authProvider";
import CustomTypeField from "../CustomFields/CustomTypeField";
import EmptyList from "../EmptyList";

import PermissionHandler from "../../config/PermissionHandler";

export default (props) => {
  var filters = [
    {
      name: "workersType",
      label: "Typ",
      reference: "workers",
      dataSource: "type",
    },
  ];

  const types = [
    // { id: 0, name: "farmaceuta [bez cen]" },
    { id: 1, name: "farmaceuta" },
    { id: 2, name: "kierownik" },
  ];

  //       { name: "farmaceuta [bez cen]", id: 0 },
  //       { name: "farmaceuta", id: 1 },
  //       { name: "kierownik", id: 2 },

  return (
    <List
      {...props}
      empty={
        <EmptyList
          title="pracowników"
          add={PermissionHandler(props.permissions, "Workers", "Create")}
        />
      }
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(
            props.permissions,
            "Workers",
            "Create"
          )}
          //filtersDataFromRecords={filters}
          //filtersDataChoices={filtersChoices}
        />
      }
      filters={
        <Filter className="d-flex flex-nowrap mt-0">
          <div style={{ marginBottom: "-19px" }} alwaysOn>
            <label>
              <strong>Punkt odbioru:</strong>
            </label>
            <ReferenceInput
              //label="Punkt odbioru"
              label=""
              source="pickupPoint"
              reference="pickup_points"
              link={false}
              alwaysOn
              filterToQuery={(searchText) => ({ internalname: searchText })}
              className="autocompleteinput"
              allowEmpty
            >
              <AutocompleteInput optionText="internalname" />
            </ReferenceInput>
          </div>
          <CustomSelectInput
            label="Typ"
            source="type"
            choices={types}
            alwaysOn
          />
        </Filter>
      }
      //filters={<PostFilter />}
      bulkActionButtons={false}
      title={`Pracownicy`}
    >
      <CustomDataGrid>
        {/* <TextField source="id" label="ID pracownika" /> */}
        <FunctionField
          label="ID pracownika"
          source="id"
          render={(record) => `${record.id.split("/")[2]}`}
        />
        <TextField source="name" label="Imię" />
        <TextField source="surname" label="Nazwisko" />
        <CustomTypeField
          source="type"
          label="Typ"
          //sortable={false}
        />
        {/* <TextField source="pickupPoint" label="Oddziały" /> */}
        <CustomBooleanField
          source="status"
          label="Status"
          //sortable={false}
        />
        {PermissionHandler(props.permissions, "Workers", "Update") && (
          <CustomActionsButtons
            label="Akcje"
            isEditAvailable={PermissionHandler(
              props.permissions,
              "Workers",
              "Update"
            )}
            isDeleteAvailable={PermissionHandler(
              props.permissions,
              "Workers",
              "Delete"
            )}
          />
        )}
      </CustomDataGrid>
    </List>
  );
};
