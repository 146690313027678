import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { SnackbarProvider } from "notistack";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const generateClassName = createGenerateClassName({
  seed: "App1",
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <SnackbarProvider
        ref={notistackRef}
        action={(
          key //TODO styles fixes
        ) => (
          <IconButton variant="light" onClick={onClickDismiss(key)}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
        maxSnack={10}
      >
        <App />
      </SnackbarProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
