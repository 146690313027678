import React, { useState } from "react";
import { useMemo } from "react";
import {
  FunctionField,
  List,
  ListBase,
  ReferenceField,
  ReferenceManyField,
  ReferenceArrayField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  TextField,
  Filter,
  TopToolbar,
  AutocompleteInput,
} from "react-admin";
import {
  withStyles,
  makeStyles,
  TextField as MUITextField,
  Card,
  CardContent,
} from "@material-ui/core";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import EmptyList from "../EmptyList";
import { CustomTextInput } from "../CustomInputs/CustomInputs";
import {
  CustomTextField,
  CustomTutorialStatusField,
} from "../CustomFields/CustomFields";
import CustomBooleanField from "../CustomFields/CustomBooleanField";
import { BackToListBtn } from "../CustomElements/PostBottomToolbars";

const CustomTitle = (props) => `Jendostka ${props.record.name}`;

export default (props) => {
  const PostShowActions = ({ basePath }) => (
    <TopToolbar>
      <BackToListBtn basePath={basePath} />
    </TopToolbar>
  );

  const ReferenceManyFieldDisplayBlock = useMemo(() => {
    return withStyles({
      root: {
        display: "block",
      },
    })(ReferenceManyField);
  }, []);

  const PayersList = (props) => (
    <ReferenceManyFieldDisplayBlock
      {...props}
      reference="tutorial_payers"
      target="tutorial"
      label={null}
      perPage={10}
      filter={{ tutorial: props.record.id }}
    >
      <List
        actions={null}
        bulkActionButtons={false}
        title={" "}
        empty={<EmptyList title="płatników dla tego szkolenia" add={false} />}
        filters={
          <Filter className="d-flex flex-nowrap mt-0">
            <div style={{ marginBottom: "-19px" }} alwaysOn>
              <label>
                <strong>Nazwa:</strong>
              </label>
              <ReferenceInput
                source="payer"
                reference="payers"
                link={false}
                filterToQuery={(searchText) => ({ internalname: searchText })}
                className="autocompleteinput"
                alwaysOn
                allowEmpty
                label=""
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </div>
          </Filter>
        }
      >
        <CustomDataGrid>
          <ReferenceField source="payer" reference="payers" label="Nazwa">
            <TextField source="name" emptyText="-" />
          </ReferenceField>
          <ReferenceField
            source="payer"
            reference="payers"
            label="Oracle Id"
            link={false}
          >
            <TextField source="oracleId" emptyText="-" />
          </ReferenceField>
          <ReferenceField
            source="payer"
            reference="payers"
            label="Adres"
            link={false}
          >
            <FunctionField
              render={(record) =>
                `${record.postCode}, ${record.city}, ${record.street}, ${record.nip}`
              }
              emptyText="-"
            />
          </ReferenceField>
          <FunctionField
            render={(record) =>
              `${record.completedWorkersCount} / ${record.allWorkersCount}`
            }
            emptyText="-"
            label="Ilość pracowników"
          />
          <CustomBooleanField
            source="isDiscount"
            label="Osiągnięcie progu rabatowego"
          />
        </CustomDataGrid>
      </List>
    </ReferenceManyFieldDisplayBlock>
  );

  return (
    <Show {...props} title={<CustomTitle />} actions={<PostShowActions />}>
      <SimpleShowLayout>
        <CustomTextField source="name" txtLabel="Nazwa szkolenia" />
        <CustomTextField source="status" txtLabel="Status" />
        <PayersList />
      </SimpleShowLayout>
    </Show>
  );
};
