import React, { Component, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import authProvider from "../../config/authProvider";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

class UserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Imie: "",
      Nazwisko: "",
      Email: "",
    };

    this.changeName = this.changeName.bind(this);
    this.changeNazwisko = this.changeNazwisko.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  changeName(e) {
    this.setState({ Imie: e.target.value }, this.updateUser);
  }

  changeNazwisko(e) {
    this.setState({ Nazwisko: e.target.value }, this.updateUser);
  }

  changeEmail(e) {
    this.setState({ Email: e.target.value }, this.updateUser);
  }

  updateUser = () => {
    this.props.updateData(this.props.idUser, {
      Name: this.state.Imie,
      Surname: this.state.Nazwisko,
      Email: this.state.Email,
    });
  };

  render() {
    return (
      <div className="m-5 d-flex flex-column" id={this.props.idUser}>
        <button
          className="btn btn-danger align-self-end"
          onClick={() => this.props.deleteFunc(this.props.idUser)}
        >
          X
        </button>
        <div className="form-group">
          <label htmlFor="username">Imię</label>
          <Input
            type="text"
            className="form-control"
            name="user['name'][]"
            value={this.state.Imie}
            onChange={this.changeName}
            validations={[required]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="username">Nazwisko</label>
          <Input
            type="text"
            className="form-control"
            name="user['surname'][]"
            value={this.state.Nazwisko}
            onChange={this.changeNazwisko}
            validations={[required]}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <Input
            type="text"
            className="form-control"
            name="user['email'][]"
            value={this.state.Email}
            onChange={this.changeEmail}
            validations={[required, email]}
          />
        </div>
      </div>
    );
  }
}

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    //this.onChangeUsername = this.onChangeUsername.bind(this);
    //this.onChangeSurname = this.onChangeSurname.bind(this);
    //this.onChangeEmail = this.onChangeEmail.bind(this);
    //this.onChangePassword = this.onChangePassword.bind(this);

    this.changeRegulations = this.changeRegulations.bind(this);

    this.state = {
      userListComponents: [],
      userListData: [],
      clientNumber: "",
      name: "",
      nip: "",
      postalCode: "",
      street: "",
      number: "",
      regulations: false,
      //password: "",
      successful: false,
      message: "",
    };

    this.idUser = 0;
  }

  onChangeClientNumber = (e) => {
    this.setState({
      clientNumber: e.target.value,
    });
  };

  onChangeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  onChangeNip = (e) => {
    this.setState({
      nip: e.target.value,
    });
  };

  onChangePostalCode = (e) => {
    this.setState({
      postalCode: e.target.value,
    });
  };

  onChangeStreet = (e) => {
    this.setState({
      street: e.target.value,
    });
  };

  onChangeNumber = (e) => {
    this.setState({
      number: e.target.value,
    });
  };

  // onChangePassword(e) {
  //   this.setState({
  //     password: e.target.value,
  //   });
  // }

  changeRegulations(e) {
    this.setState({
      regulations: e.target.checked,
    });
    //console.log(e.target.checked);
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();
    const payerData = {
      clientNumber: this.state.clientNumber,
      name: this.state.name,
      nip: this.state.nip,
      postalCode: this.state.postalCode,
      street: this.state.street,
      number: this.state.number,
    };
    const users = this.state.userListData.map((x) => x.data);

    if (this.checkBtn.context._errors.length === 0) {
      authProvider.register({ payerData, users }).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  AddUser = () => {
    let newUser = (
      <UserComponent
        key={this.idUser}
        idUser={this.idUser}
        deleteFunc={this.DeleteUser}
        updateData={this.updateUsersData}
      />
    );
    this.setState({
      userListComponents: [...this.state.userListComponents, newUser],
      userListData: [
        ...this.state.userListData,
        { idUser: this.idUser, data: null },
      ],
    });
    this.idUser++;
  };

  DeleteUser = (id) => {
    if (
      this.state.userListComponents.length > 1 &&
      this.state.userListComponents.filter(Boolean).length > 1
    ) {
      let tmp1 = this.state.userListComponents;
      let tmp2 = this.state.userListData;
      delete tmp1[id];
      delete tmp2[id];
      this.setState({ userListComponents: tmp1, userListData: tmp2 });
    }
  };

  updateUsersData = (id, newData) => {
    let tmp = this.state.userListData.map((e) => {
      return e != undefined
        ? e.idUser == id
          ? { ...e, data: newData }
          : e
        : null;
    });
    this.setState({ userListData: tmp });
  };

  componentDidMount() {
    this.AddUser();
  }

  render() {
    return (
      <div className="col">
        <div className="card card-container">
          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div
              className="d-flex bg-info align-items-center justify-content-center mb-5"
              style={{ height: 200 }}
            >
              LOGO
            </div>
            {!this.state.successful && (
              <div className="d-flex flex-column align-items-center">
                <div className="w-50">
                  <div className="form-group">
                    <label htmlFor="username">Numer klienta</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="payerData['number']"
                      value={this.state.clientNumber}
                      onChange={this.onChangeClientNumber}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Nazwa</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="payerData['name']"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">NIP</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="payerData['nip']"
                      value={this.state.nip}
                      onChange={this.onChangeNip}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Kod pocztowy</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="payerData['postCode']"
                      value={this.state.postalCode}
                      onChange={this.onChangePostalCode}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Ulica</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="payerData['street']"
                      value={this.state.street}
                      onChange={this.onChangeStreet}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Numer</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="payerData['number']"
                      value={this.state.number}
                      onChange={this.onChangeNumber}
                      validations={[required]}
                    />
                  </div>
                </div>
                <div>
                  {/* add client */}
                  <div className="row m-4 flex-wrap justify-content-around">
                    {this.state.userListComponents.map((x) => x)}
                  </div>
                  {/* add client */}
                </div>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.AddUser}
                >
                  +
                </button>

                <div className="form-group">
                  <label htmlFor="payerData[regulations]">
                    Akceptuję regulamin
                  </label>
                  <Input
                    //TODO
                    type="checkbox"
                    className="form-control"
                    name="payerData[regulations]"
                    checked={this.state.regulations}
                    value={this.state.regulations}
                    onChange={this.changeRegulations}
                    validations={[required]}
                    required
                  />
                </div>

                <button className="btn btn-primary">Zgłoś</button>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}

            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
