import * as React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteInput,
} from "react-admin";
import CustomNullableBooleanInput from "../CustomInputs/CustomNullableBooleanInput";
import authProvider from "../../config/authProvider";
import {
  CustomTextInput,
  CustomNumberInput,
  CustomSelectInput,
} from "../CustomInputs/CustomInputs";

import { required, email } from "react-admin";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

// const transform = (data) => {
//   console.log(data);
//   return {
//     ...data,
//     payer: `${data.payer.id}`,
//   };
// };

export default (props) => {
  return (
    <Create
      {...props}
      actions={null}
      title={`Dodaj płatnika użytkownika`}
      //transform={transform}
    >
      <SimpleForm
        toolbar={<PostBottomToolbarAdd />}
        //classes={root=>{overflow: }}
      >
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th style={{ width: "30%" }}>Imię</th>
                  <th>
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Nazwisko</th>
                  <th>
                    <CustomTextInput
                      source="surname"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <th>
                    <CustomTextInput source="email" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Numer Telefonu</th>
                  <th>
                    <CustomTextInput source="phone" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Typ</th>
                  <th>
                    <CustomSelectInput
                      source="type"
                      validate={requiredField}
                      choices={
                       [
                         { id: 110, name: "Płatnik Wirtualny" },
                         { id: 100, name: "Płatnik" },
                       ]
                      }
                    />
                  </th>
                </tr>
                <tr>
                  <th>Płatnik</th>
                  <th>
                    <ReferenceInput
                      source="payer"
                      reference="payers"
                      validate={requiredField}
                      filterToQuery={(searchText) => ({ name: searchText })}
                      className="autocompleteinput"
                      label=""
                      fullWidth
                      //allowEmpty
                    >
                      <AutocompleteInput />
                      {/* <SelectInput /> */}
                    </ReferenceInput>
                  </th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>
                    <BooleanInput source="status" label=" " />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
