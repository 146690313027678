import * as React from "react";
import { Edit, SimpleForm } from "react-admin";

import { CustomTextInput } from "../CustomInputs/CustomInputs";

import authProvider from "../../config/authProvider";
import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

import PermissionHandler from "../../config/PermissionHandler";

import { required, email } from "react-admin";
const requiredField = required("Pole wymagane");

const transform = (data) => ({
  //for maxorders - transform type from string to int
  ...data,
  maxorder: parseInt(data.maxorder),
});

export default (props) => {
  const CustomTitle = (props) => `Próbka ${props.record.name}`;

  return (
    <Edit
      {...props}
      actions={null}
      title={<CustomTitle />}
      transform={transform}
    >
      <SimpleForm
        toolbar={
          <PostBottomToolbarEdit
            saveRedirectPath="/samples"
            isDeleteAvailable={PermissionHandler(
              props.permissions,
              "Samples",
              "Delete"
            )}
          />
        }
      >
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped" style={{ width: "600px" }}>
                <tr>
                  <th class="col-form-label" style={{ width: "30%" }}>
                    Nazwa
                  </th>
                  <th class="col-md">
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th class="col-form-label">SKU</th>
                  <th class="col-md">
                    <CustomTextInput source="sku" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th class="col-form-label">
                    Maksymanlna ilość do zamówienia
                  </th>
                  <th class="col-md">
                    <CustomTextInput
                      source="maxorder"
                      type={"number"}
                      validate={requiredField}
                    />
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
