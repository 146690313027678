import React, { useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  email,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
} from "react-admin";
import { Field } from "react-final-form";

import {
  CustomNumberInput,
  CustomSelectInput,
  CustomTextInput,
} from "../CustomInputs/CustomInputs";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

const requiredField = required("Pole wymagane");
const isEmail = [
  required("Pole wymagane"),
  email("Podany jest nie prawidłowy e-mail"),
];

const transform = (data) => ({
  //transform type from string to int
  ...data,
  //status: JSON.parse(data.status.toLowerCase()),
  type: parseInt(data.type),
});

export default (props) => {
  const [workerType, setWorkerType] = useState();

  return (
    <Create
      {...props}
      actions={null}
      title={`Dodaj pracownika`}
      transform={transform}
    >
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          {/* col1 */}
          <div class="col-xs-6 m-1">
            <h1 class="text-center">
              <span class="badge badge-danger">Dane obowiązkowe</span>
            </h1>
            <div class="table">
              <table class="table table-striped" style={{ width: "500px" }}>
                <tr>
                  <th style={{ width: "30%" }}>Imię</th>
                  <th>
                    <CustomTextInput source="name" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Nazwisko</th>
                  <th>
                    <CustomTextInput
                      source="surname"
                      validate={requiredField}
                    />
                  </th>
                </tr>
                <tr>
                  <th>Typ</th>
                  <th>
                    <Field name="type" source="type" validate={requiredField}>
                      {({ input, meta }) => (
                        <>
                          <select
                            {...input}
                            class={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={(event) => {
                              setWorkerType(event.target.value);
                              return input.onChange(event.target.value);
                            }}
                          >
                            <option value={null} />
                            <option value={1}>farmaceuta</option>
                            <option value={2}>kierownik</option>
                          </select>
                          {meta.touched && meta.error && (
                            <span class="text-danger">
                              {meta.error.message
                                ? meta.error.message
                                : meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                    {/* <CustomSelectInput
                      source="type"
                      choices={[
                        // { id: 0, name: "farmaceuta [bez cen]" },
                        {
                          id: 1,
                          name: "farmaceuta",
                          tip:
                            "Składanie zamówień do przypisanego punktu odbioru, a także historia składanych zamówień dla danego punktu",
                        },
                        {
                          id: 2,
                          name: "kierownik",
                          tip:
                            "Ma możliwość przeglądania zamówień dla wszystkich przypisanych do siebie punktów odbioru a także składać zamówienie do wybranego punktu. Odpowiada także za dodawanie i zarządzanie pracownikami pierwszego stołu",
                        },
                      ]}
                      validate={requiredField}
                    /> */}
                    {/* <CustomSelectInput //same problem as below
                      source="type"
                      choices={[
                      //  { id: 0, name: "farmaceuta [bez cen]" },
                        { id: 1, name: "farmaceuta" },
                        { id: 2, name: "kierownik" },
                      ]}
                    /> */}
                  </th>
                </tr>
                {workerType && (
                  <tr>
                    <td colSpan={2}>
                      {workerType == 1
                        ? "Składanie zamówień do przypisanego punktu odbioru, a także historia składanych zamówień dla danego punktu"
                        : "Ma możliwość przeglądania zamówień dla wszystkich przypisanych do siebie punktów odbioru a także składać zamówienie do wybranego punktu. Odpowiada także za dodawanie i zarządzanie pracownikami pierwszego stołu"}
                    </td>
                  </tr>
                )}

                {/*<tr>*/}
                {/*  <th class="col-form-label">Punkt Odbioru</div>*/}
                {/*  <ReferenceInput source="pickupPoint" reference="pickupPoint">*/}
                {/*    <SelectInput />*/}
                {/*  </ReferenceInput>*/}
                {/*</div>*/}

                <tr>
                  <th>Punkt odbioru</th>
                  <th>
                    <ReferenceArrayInput
                      source="pickupPoint"
                      reference="pickup_points"
                      validate={requiredField}
                      filterToQuery={(searchText) => ({
                        internalname: searchText,
                      })}
                      variant="outlined"
                      label=""
                      fullWidth
                    >
                      <AutocompleteArrayInput optionText="internalname" />
                    </ReferenceArrayInput>
                  </th>
                </tr>

                <tr>
                  <th>Adres e-mail</th>
                  <th>
                    <CustomTextInput source="email" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Numer tel.</th>
                  <th>
                    <CustomTextInput source="phone" validate={requiredField} />
                  </th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>
                    {/* <CustomSelectInput
                      source="status"
                      choices={[
                        { id: false, name: "Nieaktywny" },
                        { id: true, name: "Aktywny" },
                      ]}
                    /> */}
                    {/* <CustomSelectInput
                      source="status"
                      choices={[
                        { id: false, name: "Nieaktywny" },
                        { id: true, name: "Aktywny" },
                      ]}
                      validate={requiredField}
                    /> */}
                    <BooleanInput label="" source="status" style={{margin:"0 0 -30px 0"}}/>
                  </th>
                </tr>
                <tr>
                  <td colSpan={2}>
                    Czy dany użytkownik będzie miał możliwość logowania
                  </td>
                </tr>
              </table>
            </div>
          </div>

          {/* col2 */}
          {/* <div class="col-xs-6 m-1">
            <h1 class="text-center">
              <span class="badge badge-primary">Dane dodatkowe</span>
            </h1>
            <div class="table">
              <table class="table" style={{ width: "500px" }}>
                <tr>
                  <th style={{ width: "30%" }}>PESEL</th>
                  <th>
                    <CustomNumberInput source="pesel" />
                  </th>
                </tr>
                <tr>
                  <th>NIP</th>
                  <th>
                    <CustomTextInput source="nip" />
                  </th>
                </tr>
                <tr>
                  <th>Miejscowość</th>
                  <th>
                    <CustomTextInput source="city" />
                  </th>
                </tr>
                <tr>
                  <th>Kod pocztowy</th>
                  <th>
                    <CustomTextInput source="postCode" />
                  </th>
                </tr>
                <tr>
                  <th>Ulica</th>
                  <th>
                    <CustomTextInput source="street" />
                  </th>
                </tr>
                <tr>
                  <th>Numer</th>
                  <th>
                    <CustomTextInput source="number" />
                  </th>
                </tr>
                <tr>
                  <th>Województwo</th>
                  <th>
                    <ReferenceInput
                      source="voivodeship"
                      reference="voivodeships"
                    >
                      <CustomSelectInput/>
                      {/* <SelectInput /> */}
          {/* </ReferenceInput> */}
          {/* </th> */}
          {/* </tr> */}
          {/*<tr>*/}
          {/*  <th>*/}
          {/*    <BooleanInput source="refund" label="" />*/}
          {/*  </th>*/}
          {/*  <th>Czy zgłosić pracownika do Refundacji?</th>*/}
          {/*</tr>*/}
          {/* </table> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </SimpleForm>
    </Create>
  );
};
