import * as React from "react";
import {Filter, List, ReferenceField, TextField,} from "react-admin";
import {CustomActionsButtons} from "../CustomFields/CustomActionButtonsField";
import {CustomTextInput,CustomSelectInput} from "../CustomInputs/CustomInputs";
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import CustomActionBar from "../CustomElements/CustomActionBar";

import PermissionHandler from "../../config/PermissionHandler";

import EmptyList from "../EmptyList";

const isAvailable = (record) => {
  if ([2, 3].includes(record.accepted)) return true;
  else return false;
};

const types = [
  { id: 1, name: "zaakceptowany" },
  // { id: 2, name: "oczekuje na akceptację" },
  { id: 3, name: "odrzucony" },
  { id: 4, name: "adres płatnika - do wykluczenia" },
];


export default (props) => {
  return (
    <List
      {...props}
      empty={
        <EmptyList
          title="punktów odbioru"
          add={PermissionHandler(props.permissions, "PickupPoints", "Create")}
        />
      }
      actions={
        <CustomActionBar
          isAddVisible={PermissionHandler(
            props.permissions,
            "PickupPoints",
            "Create"
          )}
          //filtersDataFromRecords={filters}
        />
      }
      filters={
        <Filter className="d-flex flex-nowrap mt-0">
          {PermissionHandler(props.permissions, "PickupPoints", "Update") && (
          <CustomSelectInput
            label="Status"
            source="accepted"
            choices={types}
            alwaysOn
          />)}
          {/*<CustomTextInput label="Nazwa" source="name" alwaysOn />*/}
          <CustomTextInput label="Nazwa" source="internalname" alwaysOn />
        </Filter>
      }
      bulkActionButtons={false}
      title={`Punkty odbioru`}
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <TextField source="city" label="Miejscowość" />
        <TextField
          source="postCode"
          label="Kod pocztowy"
          sortable={false}
        />
        <TextField source="street" label="Ulica" />
        {/* <TextField source="number" label="Numer" /> */}
        <ReferenceField
          label="Województwo"
          source="voivodeship"
          reference="voivodeships"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Płatnik"
          source="payer"
          reference="payers"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>

        {PermissionHandler(props.permissions, "PickupPoints", "Read") && (
          <CustomActionsButtons
            label="Akcje"
            isEditAvailable={PermissionHandler(
              props.permissions,
              "PickupPoints",
              "Update"
            )}
            isDeleteAvailable={PermissionHandler(
              props.permissions,
              "PickupPoints",
              "Delete"
            )}
            isDescriptionAvailable={PermissionHandler(
              props.permissions,
              "PickupPoints",
              "Read"
            )}
          />
        )}

        {PermissionHandler(
          props.permissions,
          "PickupPoints",
          "DebtCollectionEdit"
        ) && (
          <CustomActionsButtons
            label="Akcje"
            //isEditAvailable={true}
            //isDeleteAvailable={true}
            extraEditAvailableFunctionDependsOnRecord={isAvailable}
          />
        )}

      </CustomDataGrid>
    </List>
  );
};
